import React, { Component } from "react";
import "./scss/modal.scss";

class Modal extends Component {
  componentDidMount() {
    this.loadModal();
  }
  componentDidUpdate(prevProps, prevState) {
    this.loadModal();
  }

  loadModal = () => {
    var modalElem = this.refs.ModalComponent;
    var modal = window.M.Modal.init(modalElem, {
      onCloseStart: () => {
        this.props.close();
      }
    });
    if (this.props.show) {
      modal.open();
    } else {
      modal.close();
    }
  };

  render() {
    let baseClasses = "modal";
    let boxClasses = "modal-content";
    if (this.props.contentClasses !== undefined) {
      boxClasses = boxClasses + " " + this.props.contentClasses;
    }
    if (this.props.baseClasses !== undefined) {
      baseClasses = baseClasses + " " + this.props.baseClasses;
    }
    return (
      <div id={this.props.id} className={baseClasses} ref="ModalComponent">
        <div className={boxClasses}>{this.props.children}</div>
      </div>
    );
  }
}

export default Modal;
