import React from "react";
import Floor from "./floor";

const FloorList = props => {
  
  let renderFloors = () => {
    return props.floors.map(floor => {
      return (
        <Floor
          key={floor.id + " " + floor.name + " floorlist"}
          name={floor.name}
          wardens={floor.wardens}
        />
      );
    });
  };

  return (
    <div className="floorlist">
      <div className="floorlist__head row"><div className="col s7">Floor</div><div className="col s5">Floor warden Attendance</div></div>
      <div className="container extended floorlist__inner">
        {!props.no_event ? (!props.loading ? renderFloors() : null) : null}
      </div>
    </div>
  );
};

export default FloorList;
