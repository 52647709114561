import React, { Component } from "react";

import Header from "../Utilities/Header";

import { withRouter } from "react-router-dom";
import axios from "axios";
import ls from "local-storage";

import { route } from "../Routes";

import {
  CancelTokenSource,
  api_event_poll_url,
  api_logout_url
} from "../../config";

import "./scss/shell.scss";

// Add a request interceptor
axios.interceptors.request.use(
  function(config) {
    if (ls.get("auth") !== null) {
      config.headers.common["Authorization"] = ls.get("auth").access_token;
    }
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

// // Add a response interceptor
// axios.interceptors.response.use(
//   function(resp) {

//     if (resp.data !== null) {
//       if (
//         resp.data.message !== undefined &&
//         resp.data.message === "Authenticated"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             ...this.state.popup,
//             show: false
//           }
//         });
//       } else if (
//         resp.data.status !== undefined &&
//         resp.data.status === "Unauthorized"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             show: true,
//             title: "Event Ended",
//             message: resp.data.message
//           }
//         });
//       } else if (
//         resp.data.message !== undefined &&
//         resp.data.message === "Unauthenticated"
//       ) {
//         this.setState({
//           ...this.state,
//           popup: {
//             show: true,
//             title: "Logged out",
//             message: "Your session has ended, please login again"
//           }
//         });
//       }
//     }

//     return resp;
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error);
//   }
// );

const POLLING_DELAY = 10000; // 10s

class Shell extends Component {
  pollTimer = null;

  state = {
    title: "",
    updateNav: true,
    popup: {
      show: false,
      title: "",
      message: ""
    },
    unreadWardenMsg: false,
    unreadEvacueeMsg: false
  };

  headerTitle = () => {
    let curUrl = window.location.pathname;

    let curUrlArr = curUrl.split("/");
    curUrlArr.shift();
    this.setState({
      ...this.state,
      title: this.searchTitle(route, curUrlArr),
      updateNav: !this.state.updateNav
    });
  };

  searchTitle = (obj, search, i = 0) => {
    if (search[i] === undefined) {
      return obj.title;
    }
    for (var prop in obj) {
      if (search[i] === prop.toString()) {
        i = i + 1;
        return this.searchTitle(obj[prop], search, i);
      } else if ("title" === prop.toString()) {
        return obj.title;
      }
    }
  };

  /**
   * This method logs user out from the application
   * by deleting ther access token from the server
   * and also deleting local storage data
   */
  handleLogout = () => {
    axios
      .delete(api_logout_url, {
        cancelToken: CancelTokenSource.token
      })
      .then(resp => {
        ls.clear();
        this.props.history.push(route.login.link);
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.mesage);
        }
      });
  };

  pollCheckEvent = () => {
    axios
      .get(api_event_poll_url, {
        cancelToken: CancelTokenSource.token
      })
      .then(resp => {
        if (resp.data.status !== "ok" && resp.data.message !== undefined) {
          this.setState({
            ...this.state,
            popup: {
              show: true,
              message: resp.data.message
            }
          });
        }
        if (
          resp.data.status === "ok" &&
          resp.data.warden_unread_msg !== undefined &&
          resp.data.evacuee_unread_msg !== undefined
        ) {
          this.setState({
            ...this.state,
            unreadWardenMsg: resp.data.warden_unread_msg,
            unreadEvacueeMsg: resp.data.evacuee_unread_msg
          });
        }
      })
      .catch(err => {
        // if(axios.isCancel(err)) {
        //   console.log('Request Canceled',err.mesage);
        // }
      });
  };

  componentDidMount() {
    this.headerTitle();
    if (ls.get("auth") !== undefined && ls.get("auth") !== null) {
      this.pollTimer = setInterval(() => {
        if (!this.state.popup.show) {
          this.pollCheckEvent();
        }
      }, POLLING_DELAY);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      this.headerTitle();
    }
  }

  componentWillUnmount() {
    clearInterval(this.pollTimer);
  }

  renderPopup = () => {
    if (this.state.popup.show) {
      return (
        <div className="overlay_container">
          <div className="overlay_box-auto">
            <div className="overlay_message-auto blue-grey-text darken-3">
              {this.state.popup.message}
            </div>
            <div className="overlay_button_ok-auto">
              <button onClick={this.handleLogout}>Logout</button>
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  renderMessageNotification = () => {
    let message = "Unread Message from";
    if (this.state.unreadEvacueeMsg && this.state.unreadWardenMsg) {
      message = message + " Evacuees and Wardens";
    } else if (this.state.unreadEvacueeMsg) {
      message = message + " Evacuees";
    } else if (this.state.unreadWardenMsg) {
      message = message + " Wardens";
    }

    if (this.state.unreadEvacueeMsg || this.state.unreadWardenMsg) {
      return <div className="newMessageNotification">{message}</div>;
    } else {
      return null;
    }
  };

  render() {
    return (
      <React.Fragment>
        {this.renderPopup()}
        {this.renderMessageNotification()}
        <Header
          showBackBtn={this.props.showBackBtn}
          showBurgerMenu={this.props.showBurgerMenu}
          title={this.state.title}
          menuDrag={this.props.menuDrag}
          showNav={this.props.showNav}
          updateNav={this.state.updateNav}
          open={callback => {
            this.props.showSideNav(true, callback);
          }}
          close={callback => {
            this.props.showSideNav(false, callback);
          }}
        />
        {this.props.children}
      </React.Fragment>
    );
  }
}

export default withRouter(Shell);
