import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../Routes";
import { ArrowChevronIcon, MessageIcon, PhoneIcon } from "../../Icons";

class WardenList extends Component {
  state = {
    buildingIdSelected: 0,
    openRole: 0,
  };

  handleBuildingChange = e => {
    this.setState({
      buildingIdSelected: parseInt(e.target.value)
    })
    this.props.handleFilterData(parseInt(e.target.value));
  };

  toggleOpenRole = e => {
    this.setState({
      openRole: e === this.state.openRole ? 0 : e
    });
  };

  beLowercase = (e) => {
    if (e) {
      return e.replace(/\s+/g, '').toLowerCase();
    } else {
      return 'default'
    }
  }

  buildingSelect = () => {
    return (
      <div className="wardenonduty-head">
        <div className="container">
          <div className="wardenonduty__building">
            <div className="wardenonduty__building-label">
              Building :
            </div>
            <div className="wardenonduty__building-select">
              {!this.props.loader ?
                <select
                  className="browser-default"
                  value={this.props.building_id_default ? this.props.building_id_default : this.state.buildingIdSelected}
                  onChange={this.handleBuildingChange}
                >
                  {this.props.dataBuildings && this.props.dataBuildings.length > 0 ?
                    this.props.dataBuildings.map(bldg => {
                      return (
                        <option
                          key={bldg.id + " " + bldg.name + " selectoption"}
                          value={bldg.id}
                          className="floor__status--option"
                        >
                          {bldg.name}
                        </option>
                      );
                    })
                    : <option className="floor__status--option"></option>
                  }
                </select>
                :
                <div className="skeleton skeleton__field br-6" />
              }
            </div>
          </div>
          {this.props.dataBuildings && this.props.dataBuildings.length > 0 ?
            this.props.dataBuildings.map((amount, amount_idx) => (
              parseInt(amount.id) === parseInt(this.props.building_id_default) && (
                <div className="wardenonduty__displaybar" key={amount_idx}>
                  <div className="act--onduty act on--active">
                    <div className="label">On Duty</div>
                    <div className="counter">
                      {amount.total.on_duty}
                    </div>
                  </div>
                  <div className="act--unknown act on--active">
                    <div className="label">Unknown</div>
                    <div className="counter">
                      {amount.total.unknown}
                    </div>
                  </div>
                  <div className="act--notavail act on--active">
                    <div className="label">Not Avail.</div>
                    <div className="counter">
                      {amount.total.not_available + amount.total.off_duty}
                    </div>
                  </div>
                </div>
              )
            ))
            : null
          }
        </div>
      </div>
    )
  }

  wardens = () => {
    const sortOrder = ['On Duty', 'Unknown', 'Off Duty', 'Not Available'];

    return (
      this.props.dataRoles && this.props.dataRoles.length > 0 ?
        <div className="wardenonduty__lists">
          {this.props.dataRoles.map((role, role_idx) => (
            <div className="item" key={role_idx}>
              <div className="item-row">
                <div className="item-left">
                  <div className="item-place">
                    {role.wardens.length ?
                      <button
                        onClick={() => this.toggleOpenRole(role.id)}
                      >
                        <span>{role.name}</span>
                        <span className="item-arrow">
                          {this.beLowercase(this.state.openRole) === this.beLowercase(role.id) ?
                            <ArrowChevronIcon direction="up" width={14} height={14} />
                            :
                            <ArrowChevronIcon width={14} height={14} />
                          }
                        </span>
                      </button>
                      :
                      <span className="item-place-empty-warden">{role.name}</span>
                    }
                  </div>
                </div>
                <div className="item-right">
                  <div className="item-warden">
                    <span className="item-warden-counter on-duty">
                      {role.total.on_duty}
                    </span>
                    <span className="item-warden-counter unknown">
                      {role.total.unknown}
                    </span>
                    <span className="item-warden-counter not-avail">
                      {role.total.not_available + role.total.off_duty}
                    </span>
                  </div>
                </div>
              </div>

              {this.state.openRole === role.id && !!role.wardens.length && (
                <div className="item-detail">
                  <div className="item-detail-box">
                    {role.wardens.sort(
                      (a, b) =>
                        sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status) ||
                        a.name.localeCompare(b.name),
                      )
                      .map((warden, warden_idx) => (
                      <div className="card__floorwarden" key={warden_idx}>
                        <div className="card__floorwarden-left">
                          <div className={`card__floorwarden-bar color--${this.beLowercase(warden.status)}`} />
                          <div className="card__floorwarden-name">{warden.name}</div>
                        </div>
                        <div className="card__floorwarden-right">
                          <div className="cta">
                            <div className="btn-message">
                              <Link
                                to={
                                  route.message.warden.link +
                                  "/" +
                                  warden.id +
                                  "?from=" +
                                  route.warden.onduty.link
                                }
                              >
                                <MessageIcon width="25px" height="25px" fill="white" />
                              </Link>
                            </div>
                            <div className="btn-phone">
                              <a href={`tel:${warden.phone}`}>
                                <PhoneIcon width="25px" height="25px" fill="white" />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="actions on-right">
                      {role.wardens.length > 0 &&
                        <div className="btn-broadcast">
                          <Link
                            to={{
                              pathname: route.message.group.link,
                              from: route.warden.onduty.link,
                              state: {
                                audience: role.wardens,
                                sms_allowed: true
                              }
                            }}
                          >
                            <span className="btn-broadcast-text">
                              Broadcast
                            </span>
                            <span className="btn-broadcast-icon">
                              <MessageIcon width="25px" height="25px" fill="white" />
                            </span>
                          </Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              )}
            </div>
            )
          )}
        </div>
      :
        <div className="container">
          <div className="not-found">
            <p className="not-found-title">No data found</p>
          </div>
        </div>
    )
  }

  render() {
    return (
      <>
        {this.buildingSelect()}
        <div className="wardenonduty__list-head">
          <div className="container">
            <div className="wardenonduty__list-head-row">
              <div className="wardenonduty__list-head-left">
                Warden Role
              </div>
              <div className="wardenonduty__list-head-right">
                <div className="wardenonduty__list-head-right-warden">
                  Wardens
                </div>
              </div>
            </div>
            <div className="wardenonduty__list-head-overlay" />
          </div>
        </div>
        <div className="container">
          {this.wardens()}
        </div>
      </>
    );
  }
}

export default WardenList;
