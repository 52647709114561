import axios from "axios";

export const CancelToken = axios.CancelToken;
export const CancelTokenSource = CancelToken.source();

export const app = {
  name: "Allwardens Evac"
};

/**
 * Api base url constant
 */
export const api_base_url = process.env.REACT_APP_API_URL;

/**
 * Check for active event poll route
 */
export const api_event_poll_url = api_base_url + "/eventpoll";

/**
 * Login url constants
 */
export const api_email_login_url = api_base_url + "/auth/login";
export const api_logout_url = api_base_url + "/auth/logout";
export const api_peer_login_url = api_base_url + "/auth/peerlogin";
export const api_refresh_url = api_base_url + "/auth/refresh";
export const api_token_validate = api_base_url + "/auth/validate-token";
export const api_new_session_url = api_base_url + "/auth/login/new";

/**
 * ReportIn Page Url's
 */
export const api_reportin_index_url = api_base_url + "/reportin/index";

/**
 * Warden Url's
 */
export const api_warden_signin_url = api_base_url + "/warden/signin";
export const api_warden_reportin_url = api_base_url + "/warden/reportin";
export const api_warden_reportna_url = api_base_url + "/warden/reportna";
export const api_warden_off_duty_url = api_base_url + "/warden/offduty";
export const api_warden_data_url = api_base_url + "/warden/data"; // wardens onduty module
export const api_v2_warden_data_url = api_base_url + "/v2/warden/onduty"; // v2 wardens onduty module
export const api_v2_warden_filter_url = api_base_url + "/v2/warden/onduty?building_id="; // v2 wardens onduty module filter by building

/**
 * User Info Url Constants
 */
export const api_user_url = api_base_url + "/user";

/**
 * Event Management URL Constants /mgmt/event
 */

export const api_event_create_data_url = api_base_url + "/event/create/data";
export const api_event_store_url = api_base_url + "/event/create";
export const api_event_end_url = api_base_url + "/event/end";
export const api_event_buildings_url = api_base_url + "/event/buildings";
export const api_v2_event_buildings_url = api_base_url + "/v2/event/buildings";
export const api_event_building_change_url =
  api_base_url + "/event/building/change";

/**
 * Floor Status URL Costants /floor/status
 */

export const api_floor_status_data = api_base_url + "/floor/status/data";

/**
 * Floor Evacuation Constants /floor/evacuation
 */

export const api_floor_evacuation_data =
  api_base_url + "/floor/evacuation/data";

export const api_floor_evacuation_floor_update =
  api_base_url + "/floor/evacuation/updateFloor";

export const api_floor_evacuation_reported_update =
  api_base_url + "/floor/evacuation/updateReported";

export const api_floor_evacuation_floor_status_update =
  api_base_url + "/floor/evacuation/updateFloorStatus";

export const api_v2_floor_evacuation_floor_status_update =
  api_base_url + "/v2/floor/evacuation/updateFloorStatus";

export const api_floor_evacuation_building_update =
  api_base_url + "/floor/evacuation/updateBuilding";

export const api_floor_evacuation_peep_status_update =
  api_base_url + "/floor/evacuation/updatePeepStatus";

export const api_floor_evacuation_evacuee_paginated =
  api_base_url + "/floor/evacuation/evacuees/data";

export const api_floor_evacuation_evacuee_search =
  api_base_url + "/floor/evacuation/evacuees/search";

export const api_floor_evacuation_peep_search =
  api_base_url + "/floor/evacuation/peep/search";

export const api_floor_evacuation_peep_data =
  api_base_url + "/floor/evacuation/peep/data";

/**
 * Floor Evacuation Status Constants /floor/evac/status
 */

export const api_floor_evac_status_data =
  api_base_url + "/floor/evac/status/data";

export const api_floor_evac_status_change =
  api_base_url + "/floor/evac/status/change";

/**
 * Building Evacuation Status Constants /building/status
 */

export const api_building_evacuation_data =
  api_base_url + "/building/evacuation/data";

/**
 * Evacuee safety Constants /evacuee/safety
 */

export const api_evacuee_safety_data = api_base_url + "/evacuee/safety/data";
export const api_evacuee_safety_filtered =
  api_base_url + "/evacuee/safety/filtered";
export const api_evacuee_safety_setStatus =
  api_base_url + "/evacuee/safety/setStatus";
export const api_evacuee_safety_floors_data =
  api_base_url + "/evacuee/safety/floors/data";

/**
 * All Message Component Constants /message/all
 */

export const api_message_data = api_base_url + "/message/data";
export const api_message_filtered_data = api_base_url + "/message/filter/data";
export const api_cspd_filtered_data =
  api_base_url + "/message/cspd/filter/data";

export const api_warden_message_template =
  api_base_url + "/message/templates/data?type=Warden";
export const api_evacuee_message_template =
  api_base_url + "/message/templates/data?type=Evacuee";

/**
 * Warden Message Component Constants /message/warden/:wardenId
 */

export const api_warden_message_data = api_base_url + "/message/warden/data";
export const api_warden_message_send = api_base_url + "/message/warden/send";
export const api_warden_message_poll = api_base_url + "/message/warden/poll";

/**
 * Group Message Component Constants /message/group
 */

export const api_message_group_send =
  api_base_url + "/message/warden/group/send";

/**
 * Evacuee Message Component Constants /message/evacuee/:evacueeId
 */

export const api_evacuee_message_data = api_base_url + "/message/evacuee/data";
export const api_evacuee_message_send = api_base_url + "/message/evacuee/send";
export const api_evacuee_message_poll = api_base_url + "/message/evacuee/poll";
/**
 *Evacuee Group Message Component Constants /message/evacueegroup
 */

export const api_message_evacueegroup_data =
api_base_url + "/message/evacuee/group/data";

export const api_message_evacueegroup_send =
  api_base_url + "/message/evacuee/group/send";