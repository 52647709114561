import React, { Component } from "react";

class BuildingStatusTable extends Component {
  renderTableHead = () => {
    let classNames = "row table__head";
    if (this.props.site_total) {
      classNames += " site__total";
    }
    return (
      <div className={classNames}>
        <div className="col s8 head__name">{this.props.building.name}</div>
        <div className="col s4 head__time">
          ({this.props.building.duration})
        </div>
      </div>
    );
  };

  renderStatsChart = () => {
    let safe = this.props.building.stats.safe;
    let danger = this.props.building.stats.danger;
    let unknown = this.props.building.stats.unknown;
    let total = this.props.building.stats.total;
    let onDutyBar = {
      width: (safe / total) * 100 + "%"
    };
    let unknownBar = {
      width: (unknown / total) * 100 + "%"
    };
    let notAvailableBar = {
      width: (danger / total) * 100 + "%"
    };
    return (
      <div className="chart">
        <span className="chart__line chart__line--onduty" style={onDutyBar} />
        <span className="chart__line chart__line--unknown" style={unknownBar} />
        <span
          className="chart__line chart__line--danger"
          style={notAvailableBar}
        />
      </div>
    );
  };

  renderTableTotal = () => {
    return (
      <div className="row table__row">
        <div className="col s4">{this.renderStatsChart()}</div>
        <div className="col s2 row__safe--bs">
          {this.props.building.stats.safe}
        </div>
        <div className="col s2 row__unknown--bs">
          {this.props.building.stats.unknown}
        </div>
        <div className="col s2 row__danger--bs">
          {this.props.building.stats.danger}
        </div>
        <div className="col s2 row__total--bs">
          {this.props.building.stats.total}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="table">
        {this.renderTableHead()}
        {this.renderTableTotal()}
      </div>
    );
  }
}

export default BuildingStatusTable;
