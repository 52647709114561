import React, { Component } from "react";
import Modal from "../Utilities/Modal";

const FO_TYPE = {
  EMAIL: "forgot-email",
  PASSWORD: "forgot-password"
};

class ForgotOptions extends Component {
  state = {
    showPopup: false,
    popupContent: ""
  };

  showPopup = type => {
    switch (type) {
      case FO_TYPE.EMAIL:
        this.setState({
          ...this.state,
          showPopup: true,
          popupContent:
            "Check your email accounts to find email from an @allwardens.com account to identify the email address you are using."
        });
        break;
      case FO_TYPE.PASSWORD:
        this.setState({
          ...this.state,
          showPopup: true,
          popupContent:
            "If you have forgotten your password, have a Warden peer sign you in. You can reset your password after the Emergency Event from your PC"
        });
        break;
      default:
        console.error("Unknown FO_TYPE");
    }
  };

  closePopup = () => {
    this.setState({
      ...this.state,
      showPopup: false
    });
  };

  render() {
    return (
      <React.Fragment>
        <Modal
          show={this.state.showPopup}
          close={this.closePopup}
          baseClasses="FO__popup--base"
          contentClasses="FO__popup--text"
        >
          {this.state.popupContent}
        </Modal>
        <div className="row mt-20 FO__container">
          <div
            className="col s6 center-text"
            onClick={() => this.showPopup(FO_TYPE.EMAIL)}
          >
            Forgot Email
          </div>
          <div
            className="col s6 center-text"
            onClick={() => this.showPopup(FO_TYPE.PASSWORD)}
          >
            Forgot Password
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ForgotOptions;
