import React, { Component } from "react";
import EvacueeListItem from "./EvacueeListItem";

class EvacueeList extends Component {
  renderEvacueeListItems = () => {
    return this.props.evacuees.map(evacuee => {
      return (
        <EvacueeListItem
          evacuee={evacuee}
          key={evacuee.id}
          setDanger={this.props.setDanger}
          setSafe={this.props.setSafe}
          setUnknown={this.props.setUnknown}
        />
      );
    });
  };

  render() {
    return (
      <div className="container container-evacuee-safety-list">{this.renderEvacueeListItems()}</div>
    );
  }
}

export default EvacueeList;
