import React from "react";
import { Link } from "react-router-dom";
import "./scss/recipientinfo.scss";
import {
  ArrowChevronIcon, PhoneIcon,
} from "../../../Icons";
import PhoneLinkIcon from "../../../../images/icon-phonelink.png"

export const INFO_TYPE = {
  WARDEN: "warden",
  EVACUEE: "evacuee"
};

const RecipientInfo = props => {
  const renderRecipientStatus = () => {
    let status = props.recipient.status.toUpperCase();
    let statusClass = "green--bg";
    if (status === "UNKNOWN") {
      statusClass = "yellow--bg";
    } else if (status === "IN DANGER" || status === "OFF DUTY" || status === "NOT AVAILABLE") {
      statusClass = "red--bg";
    }

    let className = "recipient__status--badge " + statusClass;

    return <span className={className} data-status={props.recipient.status.toUpperCase()}>{props.recipient.status}</span>;
  };

  const renderInfo = () => {
    if (!props.loading) {
      return (
        <div className="recipient__info">
          <div className="recipient__back">
            <Link to={props.back} className="recipient__back--btn">
              <ArrowChevronIcon direction="left" width={32} height={32} fill="white" />
            </Link>
          </div>
          <div className="identity">
            <div className="identity__left">
              <div className="recipient__status">
                {renderRecipientStatus()}
              </div>
            </div>
            <div className={`identity__middle ${props.type === INFO_TYPE.WARDEN ? 'middle--warden' : 'middle--evacuee'}`}>
              <div className="recipient__name">{props.recipient.name}</div>
              {props.type === INFO_TYPE.WARDEN ? (
                <div className="recipient__role" data-type={props.type}>
                  {props.recipient.role}
                </div>
              ) : null}
              <div className="recipient__location">
                {props.recipient.location}
              </div>
            </div>

            <div className={props.type === INFO_TYPE.WARDEN ? 'identity__right--warden' : 'identity__right--evacuee'}>
              {props.type === INFO_TYPE.WARDEN ? (
              <div className="box-phonelink">
                <img src={PhoneLinkIcon} alt="phone link icon" />
              </div> ) : null }
              <div className="box-contact">
                <a href={`tel:${props.recipient.phone}`}>
                  <PhoneIcon width="20px" height="30px" fill="white" />
                </a>
              </div>
            </div>

          </div>
        </div>
      );
    } else {
      return (
        <div className="col s12 recipient__info">
          <div className="col s12 skeleton mt-10">
            <div className="col s12 skeleton skeleton__field" />
            <div className="col s12 skeleton skeleton__field" />
          </div>
        </div>
      );
    }
  };

  return (
    <div className="row message__recipient">
      {/* <div className="col s2 recipient__image"></div> */}
      {renderInfo()}
    </div>
  );
};

export default RecipientInfo;
