import React, { Component } from "react";
import ls from "local-storage";
import { withRouter } from "react-router-dom";

import { route } from "../../Routes";

export default GuardedComponent => {
  class onlyGuest extends Component {
    constructor(props, context) {
      super(props, context);
      console.log("--onlyGuest--");
    }

    isLoggedIn = () => {
      if (ls("auth") !== null) {
        this.props.history.push(route.reportin.link);
      }
    };

    componentDidMount = () => {
      this.isLoggedIn();
    };
    componentDidUpdate = (prevProps, prevState) => {
      this.isLoggedIn();
    };

    render = () => {
      return <GuardedComponent {...this.props} />;
    };
  }

  return withRouter(onlyGuest);
};
