import React from "react";

export const DOT_TYPE = {
  ODT: "on_duty",
  NA: "not_available",
  UKN: "unknown",
  OFDT: "off_duty"
};

const WardenDot = props => {
  let statusClass = "warden__dot";
  if (props.status.toString().toLowerCase() === "on_duty") {
    statusClass += " onduty";
  } else if (props.status.toString().toLowerCase() === "not_available") {
    statusClass += " notavailable";
  } else if (props.status.toString().toLowerCase() === "unknown") {
    statusClass += " unknown";
  } else if (props.status.toString().toLowerCase() === "off_duty") {
    statusClass += " offduty";
  }
  return <div className={statusClass} />;
};

export default WardenDot;
