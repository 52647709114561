import React, { Component } from "react";

import BuildingSelect from "./BuildingSelect";
import FloorWarden from "./FloorWarden";

import "./scss/floor-status.scss";
import "./scss/style.scss";

import axios from "axios";

import {
  api_v2_event_buildings_url,
  api_event_building_change_url
} from "../../../config";


class FloorStatus extends Component {
  state = {
    loading: false,
    no_event: true,
    buildings: [],
    selected_building: {}
  };

  loading = loadState => {
    this.setState({
      ...this.state,
      loading: loadState
    });
  };

  componentDidMount() {
    this.getEventBuildings();
  }

  getEventBuildings = () => {
    this.loading(true);
    axios
      .get(api_v2_event_buildings_url)
      .then(resp => {
        if (resp.data.hasOwnProperty("status")) {
          console.log(resp.data.message);
        } else {
          this.setState({
            ...this.state,
            buildings: resp.data.buildings,
            selected_building: resp.data.selected_building,
            warden_id: resp.data.warden_id,
            no_event: false
          });
        }
      })
      .finally(resp => {
        this.loading(false);
      });
  };

  renderBuildings = () => {
    return this.state.buildings.map(bldg => {
      return (
        <option
          key={bldg.id + " " + bldg.name + " selectoption"}
          value={bldg.id}
        >
          {bldg.name}
        </option>
      );
    });
  };

  handleBuildingSelectChange = buildingId => {
    //make a ajax call to get the info about buildingId
    //and store it in the selected_building state
    this.changeSelectedBuilding(buildingId);
    let selectedBldgId = this.state.buildings
      .filter(bldg => {
        return bldg.id === buildingId;
      })
      .map(bldg => {
        return bldg.id;
      })[0];
    this.getSelectedBuildingData(selectedBldgId);
  };

  changeSelectedBuilding = buildingId => {
    var _buildings = [...this.state.buildings];
    _buildings.forEach(bldg => {
      if (bldg.id === buildingId) {
        bldg.selected = true;
      } else {
        bldg.selected = false;
      }
    });
    this.setState({ ...this.state, buildings: _buildings });
  };

  getSelectedBuildingData = buildingId => {
    //axios call to backend to get the building details
    if (!this.state.no_events) {
      this.loading(true);

      axios
        .post(
          api_event_building_change_url,
          { buildingId: buildingId }
        )
        .then(resp => {
          this.setState({
            ...this.state,
            selected_building: resp.data.selected_building
          });
        })
        .finally(resp => {
          this.loading(false);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <Loader loading={this.state.loading} /> */}
        <BuildingSelect
          buildings={this.state.buildings}
          loading={this.state.loading}
          handleBuildingSelectChange={this.handleBuildingSelectChange}
          no_event={this.state.no_event}
          amount={this.state.selected_building.wardens}
        />
        <FloorWarden
          wardens={this.state.selected_building.floors}
        />
      </React.Fragment>
    );
  }
}

export default FloorStatus;
