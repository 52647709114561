import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const MessageIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '305'}
      height={height || '307'}
      overflow="hidden"
      fill={fill || 'black'}
      viewBox="0 0 305 307"
    >
      <path d="M19.05,305.26a30.36,30.36,0,0,0,33.25-6.64l31.78-31.93H254.42a51,51,0,0,0,50.82-51.17V51.77A51,51,0,0,0,254.42.61H51.07A51,51,0,0,0,.24,51.77V276.92A30.67,30.67,0,0,0,19.05,305.26ZM30.74,236V51.77A20.4,20.4,0,0,1,51.07,31.31H254.42a20.39,20.39,0,0,1,20.32,20.46V215.52A20.4,20.4,0,0,1,254.42,236h-183L30.74,276.92Z" transform="translate(-0.24 -0.61)"/>
    </svg>
  )
}

MessageIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
