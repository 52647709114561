import React, { Component } from "react";

import "./scss/event.scss";

import {
  api_event_create_data_url,
  api_event_store_url,
  api_event_end_url
} from "../../../config";
import { route } from "../../Routes";
import axios from "axios";
import { withRouter, Redirect } from "react-router";

import BuildingsList from "./BuildingsList";
import CreateEvent from "./CreateEvent";
import CreateEventNotice from "../../Utilities/CreateEventNotice";
import Modal from "../../Utilities/Modal";
import Loader from "../../Utilities/Loader";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";

class Event extends Component {
  state = {
    sms_allowed: false,
    redirectReportIn: false,
    loading: true,
    modal: {
      createEvent: false,
      endEvent: false
    },
    sites: [],
    eventTypes: [],
    userInput: {
      buildings: [],
      eventType: "",
      message: "",
      audience: "NO ONE"
    },
    activeEvent: false,
    popup: {
      show: false,
      message: "",
      redirect: false
    },
    toast: {
      show: false,
      message: ""
    }
  };

  componentDidMount() {
    this.getSites();
    if(!this.state.activeEvent){
      this.props.showBackBtn(true);
    }
  }

  loading = loading => {
    this.setState({
      ...this.state,
      loading
    });
  };

  /**
   * This methods sets the initial state
   * of the component
   */
  getSites = () => {
    this.loading(true);
    axios
      .get(api_event_create_data_url)
      .then(resp => {
        const createData = resp.data;
        this.loading(false);
        this.setState({
          ...this.state,
          sites: createData.data.sites,
          eventTypes: createData.data.eventTypes,
          activeEvent: createData.data.activeEvent,
          sms_allowed: createData.data.sms_allowed,
        });
      })
      .catch(err => {
        this.loading(false);
        this.props.history.push(route.reportin.link);
      });
  };

  /**
   * This method makes a call to the api
   * to create a event
   */
  createEvent = () => {
    this.closeCreateModal(true);
    axios
      .post(api_event_store_url, this.state.userInput)
      .then(resp => {
        if (resp.data.status === "event_created") {
          this.setState({
            ...this.state,
            loading: false,
            popup: {
              show: true,
              message: resp.data.message,
              redirect: true
            }
          });
        } else if (resp.data.status === "event_running") {
          this.setState({
            ...this.state,
            loading: false,
            popup: {
              show: true,
              message: resp.data.message,
              redirect: true
            }
          });
        }
      })
      .catch(err => {
        this.loading(false);
      });
  };

  /**
   * Shows the modal  by settings
   * modal->show to true
   */
  showCreateModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        createEvent: true
      }
    });
  };

  /**
   * This method handles confirmation Popup
   * close by setting the modal->show to false
   */
  closeCreateModal = load => {
    if (load === undefined) {
      load = false;
    }
    this.setState({
      ...this.state,
      loading: load,
      modal: {
        ...this.state.modal,
        createEvent: false
      }
    });
  };

  /**
   * This method changes the state of userInput
   * It sets the eventType to the param
   *
   * @param eventType
   * @type Int
   */
  changeEventType = (eventType, message) => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        eventType,
        message
      }
    });
  };

  /**
   * This method changes the state of userInput.
   * It sets the message to the param
   *
   * @param message
   * @type string
   */
  changeDefaultMessage = message => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        message
      }
    });
  };

  /**
   * This methods changes the state of userInput.
   * It sets the audience to the param.
   *
   * @param audience
   * @type string
   */
  changeMessageAudience = audience => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        audience
      }
    });
  };

  /**
   * This Method iterates through all the selected buildings and
   * returns a chip of it along with its name
   */
  renderSelectedBuildings = () => {
    return this.state.userInput.buildings.map(bldg => {
      return (
        <div className="chip" key={bldg.id}>
          {bldg.name}
        </div>
      );
    });
  };

  clearUserInputBuildings = () => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        buildings: []
      }
    });
  };

  updateUserInputBuildings = buildingsArray => {
    this.setState({
      ...this.state,
      userInput: {
        ...this.state.userInput,
        buildings: [...buildingsArray]
      }
    });
  };

  /**
   * Shows the modal  by settings
   * modal->show to true
   */
  showEndModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        endEvent: true
      }
    });
  };

  /**
   * This method handles confirmation Popup
   * close by setting the modal->show to false
   */
  closeEndModal = () => {
    this.setState({
      ...this.state,
      modal: {
        ...this.state.modal,
        endEvent: false
      }
    });
  };

  /**
   * Checks if everything is selected / valid
   * before it returns true
   */
  enableInitialButton = () => {
    if (
      this.state.userInput.audience !== "" &&
      this.state.userInput.buildings.length !== 0 &&
      this.state.userInput.eventType !== "" &&
      (this.state.userInput.message !== "") &&
      this.enableEndEventBtn() === false
    ) {
      return true;
    }
    return false;
  };

  enableEndEventBtn = () => {
    let selectedBuildingsLength = this.state.userInput.buildings.length;
    let countCheck = this.state.userInput.buildings.filter(bldg => {
      return bldg.hasEvent;
    }).length;

    if (selectedBuildingsLength === countCheck && countCheck !== 0) {
      return true;
    } else {
      return false;
    }
  };

  endEvents = () => {
    this.closeEndModal();
    axios
      .post(api_event_end_url, this.state.userInput.buildings)
      .then(resp => {
        if (
          resp.data.status !== undefined &&
          resp.data.status === "event_ended"
        ) {
          this.clearUserInputBuildings();
          this.getSites();
          this.setState({
            ...this.state,
            popup: {
              show: true,
              message: resp.data.message,
              redirect: true
            }
          });
        } else {
          console.log(resp.data.message);
        }
      })
      .catch(err => {
      });
  };

  renderPopup = () => {
    if (!this.state.loading && this.state.popup.show) {
      return (
        <div className="eventPopup__overlay">
          <div className="eventPopup__content">
            <div className="eventPopup__content--message">
              {this.state.popup.message}
            </div>
            <div className="eventPopup__action">
              <button onClick={this.handlePopupActionClick}>Ok</button>
            </div>
          </div>
        </div>
      );
    }
  };

  handlePopupActionClick = () => {
    if (this.state.popup.redirect) {
      this.setState({
        ...this.state,
        redirectReportIn: true
      });
    } else {
      this.setState({
        ...this.state,
        popup: {
          ...this.state.popup,
          show: false
        }
      });
    }
  };

  closeToast = () => {
    if (this.state.toast.show) {
      this.setState({
        ...this.state,
        toast: {
          show: false,
          message: ""
        }
      });
    }
  };

  render() {
    if (this.state.redirectReportIn) {
      return <Redirect to={route.reportin.link} />;
    }

    var eventName =
      this.state.eventTypes !== null
        ? this.state.eventTypes
            .filter(eventType => {
              return eventType.id === this.state.userInput.eventType;
            })
            .map(eventType => eventType.type)
        : "";

    var endEventName =
      this.enableEndEventBtn() === true
        ? this.state.userInput.buildings[0].event.type
        : "";

    return (
      <React.Fragment>
        {this.renderPopup()}
        <Loader loading={this.state.loading} />
        <Toast
          key="event Toast"
          toastType={TOAST_TYPE.WARNING}
          show={this.state.toast.show}
          close={this.closeToast}
        >
          {this.state.toast.message}
        </Toast>
        <Modal
          id="confirmationPopup"
          show={this.state.modal.createEvent}
          close={this.closeCreateModal}
          baseClasses="modal-confirmation"
          contentClasses="modal-content-confirmation"
        >
          <div className="confirm confirm-bg">
            <p className="center-align">
              Initiate Event <br /> Confirmation
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col s12">
                <label>Event : </label>
                <input
                  className="br-10"
                  type="text"
                  value={eventName}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Location : </label>
                {this.renderSelectedBuildings()}
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Message : </label>
                <textarea value={this.state.userInput.message} readOnly />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Audience : </label>
                <input
                  type="text"
                  className="br-10"
                  value={this.state.userInput.audience.toUpperCase()}
                  readOnly
                />
              </div>
            </div>
            <div className="row actionGroup">
              <div className="col s6 center-align">
                <button
                  className="btn-small green--bg"
                  onClick={this.createEvent}
                >
                  Confirm
                </button>
              </div>
              <div className="col s6 center-align">
                <button
                  className="btn-small red--bg"
                  onClick={() => this.closeCreateModal(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <Modal
          id="confirmationPopup"
          show={this.state.modal.endEvent}
          close={this.closeEndModal}
          baseClasses="modal-confirmation"
          contentClasses="modal-content-confirmation"
        >
          <div className="confirm error-bg">
            <p className="center-align">
              End Event <br /> Confirmation
            </p>
          </div>
          <div className="container">
            <div className="row">
              <div className="col s12">
                <label>Event : </label>
                <input
                  className="br-10"
                  type="text"
                  value={endEventName}
                  readOnly
                />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Location : </label>
                {this.renderSelectedBuildings()}
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Message : </label>
                <textarea value={this.state.userInput.message} readOnly />
              </div>
            </div>
            <div className="row">
              <div className="col s12">
                <label>Audience : </label>
                <input
                  type="text"
                  className="br-10"
                  value={this.state.userInput.audience.toUpperCase()}
                  readOnly
                />
              </div>
            </div>
            <div className="row actionGroup">
              <div className="col s6 center-align">
                <button
                  className="btn-small green--bg"
                  onClick={this.endEvents}
                >
                  Confirm
                </button>
              </div>
              <div className="col s6 center-align">
                <button
                  className="btn-small red--bg"
                  onClick={this.closeEndModal}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </Modal>

        <div className="container">
          <CreateEventNotice show={!this.state.activeEvent} />

          <div className="col s12 event__createForm">
            <BuildingsList
              sites={this.state.sites}
              endEventBtnEnable={this.enableEndEventBtn()}
              updateSelectedBuildings={this.updateUserInputBuildings}
              clearSelectedBuildings={this.clearUserInputBuildings}
              loading={this.state.loading}
              showModal={this.showEndModal}
            />
            <CreateEvent
              eventTypes={this.state.eventTypes}
              initiateEventBtnEnable={this.enableInitialButton()}
              changeType={this.changeEventType}
              changeMessage={this.changeDefaultMessage}
              changeAudience={this.changeMessageAudience}
              showModal={this.showCreateModal}
              loading={this.state.loading}
              input={this.state.userInput}
              activeEvent={this.state.activeEvent}
              sms_allowed={this.state.sms_allowed}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(Event);
