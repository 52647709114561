import React from "react";
import CreateEventNotice from "../Utilities/CreateEventNotice";
import {
  BuildingIcon,
  SafetyHelmetIcon,
  Warning2Icon,
  LocationIcon
} from "../Icons";

const listLocations = props => {
  return props.locations && props.locations instanceof Array ? (
    <ul className="collection">
      {props.locations.map((location, index) => {
        return (
          <li
            key={location.building + location.site + index}
            className="collection-item"
          >
            {location.site} - {location.building}
          </li>
        );
      })}
    </ul>
  ) : (
    <input
      type="text"
      name="location"
      id="location"
      className="report__form--textbox"
      value="Not Applicable"
      readOnly
    />
  );
};

const listAreas = props => {
  if (props.area && props.area.locations instanceof Array) {
    let locations = props.area.locations.toString().split(",");
    return (
      <ul className="collection">
        {locations.map((location, index) => {
          return (
            <li
              key={location + " " + index}
              className="collection-item"
            >
              {location}
            </li>
          );
        })}
      </ul>
    );
  } else {
    return (
      <input
        type="text"
        name="location"
        id="location"
        className="report__form--textbox"
        value="Not Applicable"
        readOnly
      />
    );
  }
};

const ReportForm = props => {
  let loading = "Loading...";
  let current_events = loading;
  let role = loading;
  let area = loading;
  if (props.loading !== true) {
    current_events = props.current_events;
    role = props.role.Warden_Role;
    area = props.area;
  }

  return (
    <div className="container">
      <div className="report__wrapper">
        <CreateEventNotice show={!props.active_event} />

        <form className="report__form">
          <div className="row report__form-row">
            <label className="report__form--label">Current Event</label>
            <div className="report__form-field">
              <span className="icon icon--event">
                <Warning2Icon width={21} height={21} />
              </span>
              <input
                type="text"
                name="current_events"
                id="current_events"
                value={current_events}
                readOnly
                className="report__form--textbox textbox--event"
              />
            </div>
          </div>

          <div className="row report__form-row">
            <label className="report__form--label">Building(s) Included</label>
            <div className="report__form-field">
              <span className="icon icon--building">
                <BuildingIcon width={22} height={22} />
              </span>
              {props.loading ? (
                <ul className="collection">
                  <li className="collection-item">
                    {loading}
                  </li>
                </ul>
              ) : (
                listLocations(props)
              )}
            </div>
          </div>

          <div className="row report__form-row">
            <label className="report__form--label">Role</label>
            <div className="report__form-field">
              <span className="icon icon--role">
                <SafetyHelmetIcon width={22} height={22} />
              </span>
              <input
                type="text"
                name="role"
                id="role"
                className="report__form--textbox"
                value={role || ""}
                readOnly
              />
            </div>
          </div>

          <div className="row report__form-row">
            <label className="report__form--label">
              My Areas <span className="report__form--domain">{area !== undefined ? area.domain+ " domain" : "NA"}</span>
            </label>
            <div className="report__form-field">
              <span className="icon icon--area">
                <LocationIcon width={30} height={30} />
              </span>
              {props.loading ? (
                <ul className="collection">
                  <li className="collection-item">
                    {loading}
                  </li>
                </ul>
              ) : (
                listAreas(props)
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportForm;
