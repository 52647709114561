import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const ResponseRequiredIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '110'}
      height={height || '110'}
      fill={fill || 'black'}
      viewBox="0 0 110 110"
    >
      <path
        fill={fill || 'black'}
        d="M109.584 109.583L88.75 88.75H15.834c-2.865 0-5.317-1.02-7.357-3.06s-3.06-4.492-3.06-7.357v-62.5c0-2.864 1.02-5.317 3.06-7.357s4.492-3.06 7.357-3.06h83.333c2.865 0 5.317 1.02 7.357 3.06s3.06 4.493 3.06 7.357v93.75z"
      ></path>
      <path
        fill="#fff"
        d="M40.983 47.131c-3.756 0-6.972-1.338-9.648-4.013-2.675-2.675-4.013-5.891-4.013-9.648 0-3.757 1.338-6.973 4.013-9.648 2.676-2.676 5.892-4.013 9.648-4.013 3.757 0 6.973 1.337 9.649 4.013 2.675 2.675 4.013 5.891 4.013 9.648 0 3.757-1.338 6.973-4.013 9.648-2.676 2.675-5.892 4.013-9.648 4.013zM13.662 74.453v-9.562c0-1.936.498-3.715 1.494-5.337.996-1.622 2.32-2.86 3.97-3.714 3.53-1.765 7.116-3.088 10.759-3.97a46.989 46.989 0 0111.1-1.324c3.756 0 7.456.441 11.1 1.324 3.642.882 7.228 2.205 10.758 3.97 1.65.854 2.974 2.092 3.97 3.714.996 1.622 1.494 3.401 1.494 5.337v9.562H13.66z"
      ></path>
      <g clipPath="url(#clip0_1060_3674)">
        <path
          fill="#fff"
          d="M98.104 62.322V52.76c0-1.993-.697-3.686-2.092-5.08-1.394-1.395-3.088-2.093-5.08-2.093H68.997l8.607 8.607-3.407 3.347-14.345-14.344 14.345-14.345 3.407 3.348-8.607 8.606h21.935c3.307 0 6.126 1.166 8.457 3.497 2.331 2.33 3.496 5.15 3.496 8.457v9.563h-4.781z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1060_3674">
          <path
            fill="#fff"
            d="M0 0H57.377V57.377H0z"
            transform="translate(49.18)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  )
}

ResponseRequiredIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
