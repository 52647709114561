import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const NoReplyIcon = ({ width, height, fill }) => {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '306'}
			height={height || '308'}
			overflow="hidden"
			viewBox="0 0 368 370"
			fill={fill || 'black'}
		>
			<g fillRule="evenodd">
				<path
					d="M2385.72 1136.71c13.71 5.72 29.5 2.57 40.01-7.99l38.23-38.38h204.97c33.78 0 61.16-27.54 61.16-61.5V832.027c0-33.959-27.38-61.5-61.16-61.5h-244.68c-33.78 0-61.16 27.541-61.16 61.5v270.613c0 14.91 8.93 28.36 22.63 34.07zm14.07-83.27V832.027c0-13.584 10.96-24.601 24.46-24.601h244.68c13.5 0 24.46 11.017 24.46 24.601v196.813c0 13.59-10.96 24.6-24.46 24.6h-220.2l-48.94 49.2z"
					transform="translate(-2363 -770)"
				></path>
				<path
					d="M2451 931c0-50.258 40.74-91 91-91s91 40.742 91 91-40.74 91-91 91-91-40.742-91-91zm148.08 43.659c24.11-31.522 18.1-76.622-13.42-100.734-25.77-19.712-61.55-19.712-87.32 0zm-114.16-87.318c-24.11 31.522-18.1 76.623 13.42 100.735 25.77 19.714 61.55 19.714 87.32 0z"
					transform="translate(-2363 -770)"
				></path>
			</g>
    </svg>
  )
}

NoReplyIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
