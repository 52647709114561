import React, { Component } from "react";
import { withRouter } from "react-router";

import RecipientInfo, { INFO_TYPE } from "../Template/RecipientInfo";
import MessageBubble, { BUBBLE } from "../Template/MessageBubble";
import MessageBox from "../Template/MessageBox";
import Toast, { TOAST_TYPE } from "../../Utilities/Toast";
import {
  api_warden_message_data,
  api_warden_message_send,
  api_warden_message_poll,
  CancelTokenSource
} from "../../../config";

import axios from "axios";

import "./scss/main.scss";

const POLLING_DELAY = 5000; //ms (milliseconds)

class WardenMessage extends Component {
  messagesEnd = React.createRef();

  timer = null;

  state = {
    toWarden: 0,
    fromUrl: "",
    loader: true,
    recipient: {
      id: "",
      name: "",
      phone: "",
      location: "",
      status: ""
    },
    messages: [],
    sms_allowed: false,
    toast: {
      type: TOAST_TYPE.DANGER,
      show: false,
      text: ""
    }
  };

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search);

    this.setState(
      {
        ...this.state,
        toWarden: this.props.match.params.wardenId,
        fromUrl: params.get("from")
      },
      () => {
        this.getInitialState();
      }
    );

    this.timer = setInterval(() => {
      this.pollForMessages();
    }, POLLING_DELAY);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  /**
   * This method checks for new messages
   */
  pollForMessages = () => {
    axios
      .get(api_warden_message_poll, {
        cancelToken: CancelTokenSource.token,
        params: {
          toWarden: this.state.toWarden
        }
      })
      .then(resp => {
        let toScroll = false;
        if (resp.data.messages.length !== this.state.messages.length) {
          toScroll = true;
        }
        this.setState(
          {
            ...this.state,
            ...resp.data
          },
          () => {
            if (toScroll) {
              this.scrollToBottom();
            }
          }
        );
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Couldnt Poll for new data. Please refresh."
          );
        }
      });
  };

  loader = loader => {
    this.setState({
      ...this.state,
      loader
    });
  };

  getInitialState = () => {
    this.loader(true);
    axios
      .get(api_warden_message_data, {
        cancelToken: CancelTokenSource.token,
        params: {
          toWarden: this.state.toWarden
        }
      })
      .then(resp => {
        this.setState({
          ...this.state,
          ...resp.data,
          loader: false
        });
        this.scrollToBottom();
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.loader(false);
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Error loading data. Please refresh."
          );
        }
      });
  };

  scrollToBottom = () => {
    if (this.messageEnd !== null) {
      this.messagesEnd.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  componentDidUpdate = (prevState, prevProps) => {
    window.addEventListener("resize", this.scrollToBottom);
  };

  send = message => {
    let data = {
      message: message,
      receiverId: this.state.recipient.id
    };

    axios
      .post(api_warden_message_send, data, {
        cancelToken: CancelTokenSource.token,
      })
      .then(resp => {
        if (resp.data.status === "message_sent") {
          this.setState({
            ...this.state,
            messages: [...resp.data.newMessages]
          });
          this.scrollToBottom();
        } else {
          this.setToast(TOAST_TYPE.DANGER, true, resp.data.message);
        }
      })
      .catch(err => {
        if (axios.isCancel(err)) {
          console.log("Request Canceled", err.message);
        } else {
          this.setToast(
            TOAST_TYPE.DANGER,
            true,
            "Error while sending message. Please refresh."
          );
        }
      });
  };

  canSend = () => {
    return this.state.recipient.status.toLowerCase() === "on duty" ||
      this.state.sms_allowed
      ? true
      : false;
  };

  renderMessageHistory = () => {
    return !this.state.loader ? (
      this.state.messages.map(message => {
        let type =
          this.state.recipient.id === message.senderId
            ? BUBBLE.FROM
            : BUBBLE.TO;
        return (
          <MessageBubble
            key={message.messageId}
            type={type}
            message={message}
            recipient={this.state.recipient}
          />
        );
      })
    ) : (
      <React.Fragment>
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.TO} />
        <MessageBubble loading={this.state.loader} type={BUBBLE.FROM} />
      </React.Fragment>
    );
  };

  renderNoMessagesError = () => {
    return !this.state.loader && this.state.messages.length === 0 ? (
      <div className="col s12 center-text message__history--nomessage">
        No Messages
      </div>
    ) : null;
  };

  setToast = (type, show = true, text) => {
    this.setState({
      ...this.state,
      toast: {
        type,
        show,
        text
      }
    });
  };

  closeToast = () => {
    this.setState({
      ...this.state,
      toast: {
        show: false
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Toast
          show={this.state.toast.show}
          close={this.closeToast}
          delay={2000}
        >
          {this.state.toast.text}
        </Toast>
        <RecipientInfo
          loading={this.state.loader}
          recipient={this.state.recipient}
          back={this.state.fromUrl}
          type={INFO_TYPE.WARDEN}
        />
        <div className="row message__history">
          {this.renderMessageHistory()}
          {this.renderNoMessagesError()}
          <div ref={this.messagesEnd} />
        </div>
        <MessageBox
          loading={this.state.loader}
          send={this.send}
          canSend={this.canSend()}
          sms={this.state.sms_allowed}
          status={this.state.recipient.status}
          group={false}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(WardenMessage);
