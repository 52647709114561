import React, { Component } from "react";

class MessageFilterSelector extends Component {

  beLowercase = (e) => {
    if (e) {
      return e.replace(/\s+/g, '').toLowerCase();
    } else {
      return 'default';
    }
  }

  renderSelectionList = () => {
    return (
      this.props.selectorContent.map(content => {
        let id = content.id !== undefined ? content.id : content.name;
        return (
          <div
            key={content.id + content.name + "__" + this.props.label}
            className={`custom-checkbox status--${this.beLowercase(content.name)}`}
          >
            <label>
              <input
                type="checkbox"
                value={id}
                onChange={
                  () => {
                    this.props.update(id)
                    setTimeout(() => {
                      this.props.onClose()
                    }, 1000);
                  }
                }
                checked={
                  this.props.selected.includes("all") ||
                  this.props.selected.includes(id.toString())
                }
              />
              <span>{content.label}</span>
            </label>
          </div>
        );
      })
    );
  };

  render() {
    return (
      <div className="col-right">
        <div className="box-statuses">
          {this.renderSelectionList()}
        </div>
      </div>
    );
  }
}

export default MessageFilterSelector;
