import React from "react";
import { CloseIcon, SearchIcon } from "../../Icons";

const SearchBar = props => {

  const renderClearButton = () => {
    if (props.text.length > 0) {
      return (
        <button
          className="search__clear"
          onClick={() => handleChange("")}
        >
          <CloseIcon width="12px" height="12px" fill={'black'} />
        </button>
      );
    }
  };

  const handleChange = (value) => {
    if(props.typeSearch && props.typeSearchLength !== undefined)
    {
      if(value.length >= props.typeSearchLength || value.length === 0) {
        props.updateText(value,props.filterData(value));
      }else{
        props.updateText(value);
      }
    }else{
      props.updateText(value);
    }
  }

  const searchField = () => {
    return (
      <div className="wardenmessaging__search">
        <div className="search__bgoverlay" />
        <div className="container px-10">
          <div className="search__field">
            <span className="search__icon--magnifying-glass">
              <SearchIcon width="20px" height="20px" fill="#aaa" />
            </span>
            <input
              className="search__input browser-default"
              type="text"
              value={props.text}
              onChange={(e) => handleChange(e.target.value)}
              placeholder="Search"
            />
            {renderClearButton()}
          </div>
        </div>
      </div>
    )
  }

  return searchField();
};

export default SearchBar;
