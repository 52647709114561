import React from "react";
import ReactDOM from "react-dom";
import "./scss/index.scss";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
/**
 * Set default headers for every axios request
 */
axios.defaults.headers.common["Content-Type"] = "application/json";
axios.defaults.headers.common["Accept"] = "application/json";
// if(ls.get("auth") !== undefined) {
//     axios.defaults.headers.common["Authorization"] = ls.get("auth").access_token;
// }




ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register();
serviceWorker.unregister();
