import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const PeepIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '160'}
      height={height || '206'}
      overflow="hidden"
      fill={fill || 'black'}
      viewBox="0 0 160 206"
    >
      <path d="M120.22,172.4a11.72,11.72,0,0,0-16.51-1.4,48.17,48.17,0,0,1-71-63.68A11.75,11.75,0,1,0,13.18,94.23,71.63,71.63,0,0,0,118.82,189,11.8,11.8,0,0,0,120.22,172.4Z" transform="translate(-1)" />
      <path d="M160.5,177.22,141,116a10.6,10.6,0,0,0-10.12-7.37h-62V88.46h41.3a10.62,10.62,0,1,0,0-21.23H68.88v-23a23.59,23.59,0,1,0-21.28.66l.07,74.31a10.68,10.68,0,0,0,10.64,10.62h64.8l17.14,53.81A10.61,10.61,0,0,0,150.38,191a10.29,10.29,0,0,0,3.25-.52A10.58,10.58,0,0,0,160.5,177.22Z" transform="translate(-1)" />
    </svg>
  )
}

PeepIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
