import React, { Component } from "react";

var BreakException = {};

class BuildingsList extends Component {
  state = {
    sitesCheckboxes: []
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.sites !== this.props.sites) {
      var sites = [];
      this.props.sites.forEach(site => {
        let siteObj = {};
        siteObj.id = site.id;
        siteObj.name = site.name;
        siteObj.checked = false;
        let buildings = [];
        site.buildings.forEach(bldg => {
          let building = {};
          building.id = bldg.id;
          building.name = bldg.name;
          building.checked = false;
          building.hasEvent =
            bldg.event !== "" && bldg.event !== undefined ? true : false;
          building.event = bldg.event;
          buildings = [...buildings, building];
        });
        siteObj.buildings = buildings;
        sites.push(siteObj);
      });
      this.setState({
        ...this.state,
        sitesCheckboxes: [...sites]
      });
    }
  }

  /**
   * This method render's sites and its corresponding buildings
   *
   * @return JSX Component
   */
  renderSites = () => {
    if (this.props.loading) {
      return "Loading...";
    }
    return this.state.sitesCheckboxes.map(site => {
      return (
        <ul className="event__selectGroup" key={site.id + site.name}>
          <li className="event__selectGroupTopItem">
            <label>
              <input
                type="checkbox"
                className="filled-in"
                value={site.id}
                checked={site.checked}
                onChange={e => this.siteCheckHandle(e, site)}
              />
              <span className="event__selectGroupTopItem--name">
                {site.name}
              </span>
            </label>
          </li>
          {this.renderBuildings(site.buildings, site.id)}
        </ul>
      );
    });
  };

  /**
   * This method just renders buildings
   * --called inside rendersites
   *
   * @param buildings - Object
   * @param siteId - Int
   * @return JSX Component
   */
  renderBuildings = (buildings, siteId) => {
    return buildings.map(building => {
      var siteID = siteId;
      // var buildingId = building.id;
      return (
        <li
          className="event__selectGroupItem"
          key={building.id + building.name}
        >
          <label>
            <input
              type="checkbox"
              className="filled-in"
              value={building.id}
              checked={building.checked}
              onChange={e => this.buildingCheckHandle(e, building, siteID)}
            />
            <span className="event__selectGroupItem--name">
              {building.name}
            </span>
            {building.event.length !== 0 ? (
              <span className="event__selectGroupItem--eventName">
                {building.event.type}
              </span>
            ) : (
              ""
            )}
          </label>
        </li>
      );
    });
  };

  /**
   * Checks if all the buildings in a site has event
   */
  checkAllBuildingsHasEvent = siteId => {
    var sites = [...this.state.sitesCheckboxes];
    let response = false;
    try {
      sites.forEach(site => {
        if (site.id === siteId) {
          site.buildings.forEach(bldg => {
            if (bldg.hasEvent) {
              response = true;
            } else {
              response = false;
              throw BreakException;
            }
          });
        }
      });
    } catch (e) {
      if (e !== BreakException) {
        throw e;
      } else {
        return response;
      }
    }

    return response;
  };

  /**
   * Check if all the buildings in a site has been checked
   */

  hasCheckedAllBuildings = siteId => {
    var sites = [...this.state.sitesCheckboxes];
    let response = false;
    try {
      sites.forEach(site => {
        if (site.id === siteId) {
          site.buildings.forEach(bldg => {
            if (bldg.checked) {
              response = true;
            } else {
              response = false;
              throw BreakException;
            }
          });
        }
      });
    } catch (e) {
      if (e !== BreakException) {
        throw e;
      } else {
        return response;
      }
    }
    return response;
  };

  /**
   * Toggles a building checkbox to true of false
   * - Toggles the corresponding checkbox
   * - Unchecks the site checkbox if the building has event
   *
   * @param buildingId - Int
   * @param siteId - Int
   * @param toggleValue - Boolean
   */
  toggleCheckBuilding = (buildingId, siteId, toggleValue) => {
    var sites = [...this.state.sitesCheckboxes];
    sites.forEach(site => {
      if (site.id === siteId) {
        site.buildings.forEach(bldg => {
          if (bldg.id === buildingId) {
            bldg.checked = toggleValue;
          }
          if (bldg.hasEvent) {
            if (this.hasCheckedAllBuildings(siteId)) {
              site.checked = true;
            } else {
              site.checked = false;
            }
          } else {
            if (this.hasCheckedAllBuildings(siteId)) {
              site.checked = true;
            } else {
              site.checked = false;
            }
          }
        });
      } else {
        site.checked = false;
        site.buildings.forEach(bldg => {
          bldg.checked = false;
        });
      }
    });
    this.setState({
      ...this.state,
      sitesCheckboxes: [...sites]
    });
  };

  /**
   * Toggles a site checkbox to true or false
   * and then calls a method to toggle of the
   * site's buildings,
   * if the site's buildings has event then it
   * always unchecks it
   *
   * @param siteId - Int
   * @param toggleValue - Boolean
   */
  toggleCheckSites = (siteId, toggleValue) => {
    var sites = [...this.state.sitesCheckboxes];
    sites.forEach(site => {
      if (site.id === siteId) {
        site.checked = toggleValue;
        this.toggleSiteBuildings(site.id, toggleValue);
      } else if (this.checkAllBuildingsHasEvent(siteId)) {
        site.checked = toggleValue;
        this.toggleSiteBuildings(site.id, toggleValue);
      } else {
        site.checked = false;
        this.toggleSiteBuildings(site.id, false);
      }
    });
    this.setState({
      ...this.state,
      sitesCheckboxes: [...sites]
    });
  };

  /**
   * Toggles all the buildings in a site unless
   * it has a event, in that case it always
   * sets it to false
   *
   * @param siteId - Int
   * @param toggleValue - Boolean
   */
  toggleSiteBuildings = (siteId, toggleValue) => {
    var sites = [...this.state.sitesCheckboxes];
    sites.forEach(site => {
      if (site.id === siteId) {
        site.buildings.forEach(bldg => {
          if (this.checkAllBuildingsHasEvent(siteId)) {
            bldg.checked = toggleValue;
          } else {
            if (bldg.hasEvent) {
              bldg.checked = false;
            } else {
              bldg.checked = toggleValue;
            }
          }
        });
      }
    });
    this.setState({
      ...this.state,
      sitesCheckboxes: [...sites]
    });
  };

  /**
   * This functions handles a event when a
   * building checkbox is clicked
   *
   * @param e - Event Object
   * @param building - Object
   * @param siteId - Int
   */
  buildingCheckHandle = (e, building, siteId) => {
    //Toggle the checkbox
    this.toggleCheckBuilding(building.id, siteId, !building.checked);
    //Toggle based on the checkbox has event
    this.toggleHasEventBuildings(siteId, !building.hasEvent);
    //Final method to call to handle the Change
    this.handleBuildingChange();
  };

  /**
   * This method checks and unchecks the buildings
   * based on if they have current event or not
   * Eg. If we check a building with an event
   *    we can only check another building with
   *    an event else this method will uncheck
   *    any other building with has no events
   *    and vice versa
   *
   * @param siteId - Int
   * @param hasEvent - Boolean
   */
  toggleHasEventBuildings = (siteId, hasEvent) => {
    var sites = [...this.state.sitesCheckboxes];
    sites.forEach(site => {
      if (site.id === siteId) {
        site.buildings.forEach(bldg => {
          if (hasEvent && bldg.hasEvent) {
            bldg.checked = false;
          } else {
            if (!hasEvent && !bldg.hasEvent) {
              bldg.checked = false;
            }
          }
        });
      }
    });
  };

  /**
   * This method returns all the buildings that
   * has building.checked = true in an array
   *
   * @return checkedBuildings - Array
   */
  getCheckedBuildings = () => {
    let sites = [...this.state.sitesCheckboxes];
    let checkedBuildings = [];
    sites.forEach(site => {
      site.buildings.forEach(bldg => {
        if (bldg.checked) {
          checkedBuildings.push(bldg);
        }
      });
    });
    return checkedBuildings;
  };

  handleBuildingChange = () => {
    // // Need to fix this..
    // if (e.target.checked) {
    //   this.props.addBuilding(parseInt(e.target.value, 10));
    // } else {
    //   this.props.removeBuilding(parseInt(e.target.value, 10));
    // }
    let checkedBuildings = this.getCheckedBuildings();
    this.props.updateSelectedBuildings(checkedBuildings);
  };

  /**
   * This method handles a event when a site's
   * checkbox is clicked
   *
   * @param e - Event Object
   * @param siteId - Int
   */
  siteCheckHandle = (e, site) => {
    this.toggleCheckSites(site.id, !site.checked);
    this.handleBuildingChange();
  };

  handleEndEvents = () => {
    this.props.showModal();
  };

  /**
   * This method renders End Event button
   *
   * @return JSX Component
   */
  renderEndEventButton = () => {
    return this.props.endEventBtnEnable === true ? (
      <button
        className="event__endButton mt-10 event__endButton--active"
        onClick={this.handleEndEvents}
      >
        End Event
      </button>
    ) : (
      <button className="event__endButton mt-10" disabled>
        End Event
      </button>
    );
  };

  render() {
    return (
      <div className="row mt-20">
        <div className="col s12">
          <label className="event__createForm--label">Building(s):</label>
          <div className="event__selectBuildings">{this.renderSites()}</div>
          {this.renderEndEventButton()}
        </div>
      </div>
    );
  }
}

export default BuildingsList;
