import React, { Component } from "react";

class LoginOptions extends Component {
  state = {
    email: true
  };

  switchForm = event => {
    if (event.target.name === "email") {
      this.setState({
        email: true
      });
    } else {
      this.setState({
        email: false
      });
    }
    this.props.showForm(event.target.name);
  };

  renderButtons = () => {
    let email = "";
    let peer = "";

    if (this.state.email) {
      email = "btn-small btn-flat blue grey-text text-lighten-3";
      peer = "btn-small btn-flat grey lighten-3";
    } else {
      peer = "btn-small btn-flat blue grey-text text-lighten-3";
      email = "btn-small btn-flat grey lighten-3";
    }

    return (
      <React.Fragment>
        <button onClick={this.switchForm} name="email" className={email}>
          Email
        </button>
        <button name="peer" onClick={this.switchForm} className={peer}>
          Warden Peer
        </button>
      </React.Fragment>
    );
  };

  render() {
    return (
      <div className="login__options col s12 center-align">
        <p className="center-align pb-20">Alternative Sign In Options</p>
        {this.renderButtons()}
      </div>
    );
  }
}

export default LoginOptions;
