import React, { Component } from "react";

import BuildingStatusTable from "./BuildingStatusTable";

import { api_building_evacuation_data } from "../../../config";
import EvacueeStatusSummary from "../../Utilities/EvacueeStatusSummary";

import axios from "axios";

import "./scss/building-status.scss";

class BuildingStatus extends Component {
  state = {
    loading: false,
    no_event: false,
    site: "Thomas St",
    total: {
      name: "Site Total",
      duration: "1hr",
      stats: {
        safe: 22,
        unknown: 8,
        danger: 2,
        total: 32
      }
    },
    buildings: [
      {
        id: 1,
        name: "Building A",
        stats: {
          safe: 12,
          unknown: 3,
          danger: 2,
          total: 17
        },
        duration: "1hr"
      },
      {
        id: 2,
        name: "Building B",
        stats: {
          safe: 10,
          unknown: 5,
          danger: 0,
          total: 15
        },
        duration: "30mins"
      }
    ]
  };

  loading = loading => {
    this.setState({
      ...this.state,
      loading
    });
  };

  componentDidMount() {
    this.getData();
  }

  getData = () => {
    this.loading(true);
    axios
      .get(api_building_evacuation_data)
      .then(res => {
        if (res.data.length === 0) {
          this.setState({
            ...this.state,
            no_event: true
          });
        } else if (res.data.status !== undefined) {
          console.log(res.data.message);
        } else {
          this.setState({
            ...this.state,
            ...res.data
          });
        }
        this.loading(false);
      })
      .catch(err => {
        this.loading(false);
      });
  };

  renderSiteName = () => {
    return (
      <div className="row mt-10">
        {this.state.loading ? (
          <div className="skeleton skeleton__field br-10" />
        ) : (
          <React.Fragment>
            <div className="col s2 site__label">
              <span className="label">Site:</span>
            </div>
            <div className="col s10 site__textbox">
              {this.state.no_event ? "No Current Events" : this.state.site}
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderColumnIndicators = () => {
    return (
      <div className="row column__indicators">
        {this.state.loading ? (
          <div className="skeleton skeleton__field mt-30" />
        ) : (
          <React.Fragment>
            <div className="col s4" />
            <div className="col s2">
              <div className="indicator__safe--bs">Safe</div>
            </div>
            <div className="col s2">
              <div className="indicator__unknown--bs">Unknown</div>
            </div>
            <div className="col s2">
              <div className="indicator__danger--bs">Danger</div>
            </div>
            <div className="col s2">
              <div className="indicator__total--bs">Total</div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  };

  renderBuildingStatusTables = () => {
    if (!this.state.loading) {
      return this.state.buildings.map(building => {
        return (
          <BuildingStatusTable
            key={building.id + building.name + "StatusTable"}
            building={building}
          />
        );
      });
    } else {
      return <div className="skeleton skeleton__field mt-30" />;
    }
  };

  renderSiteTotalTable = () => {
    if (!this.state.loading) {
      return (
        <BuildingStatusTable
          site_total={true}
          building={this.state.total}
          loading={this.state.loading}
        />
      );
    } else {
      return <div className="skeleton skeleton__field mt-30" />;
    }
  };

  renderStatusSummary = () => {
    return this.state.loading ? (
      <div className="skeleton skeleton__field mt-30" />
    ) : (
      <EvacueeStatusSummary showBar={true} summary={this.state.total.stats} />
    );
  };

  render() {
    return (
      <React.Fragment>
        <div className="container extended">
          {this.renderSiteName()}
          {this.renderStatusSummary()}
          {/* {this.state.no_event ? null : this.renderColumnIndicators()} */}
          {this.state.no_event ? null : this.renderBuildingStatusTables()}
          {/* {this.state.no_event ? null : this.renderSiteTotalTable()} */}
        </div>
      </React.Fragment>
    );
  }
}

export default BuildingStatus;
