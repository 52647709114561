import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const HelmetIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.11 17.96C22.92 17.76 22.66 17.64 22.39 17.64H22.24V14.99C22.24 12.57 21.44 10.19 19.98 8.3C19.14 7.21 18.13 6.32 16.97 5.65L16.88 5.6C16.25 5.25 15.59 4.97 14.91 4.76C14.95 4.3 14.83 3.85 14.57 3.53C14.3 3.19 13.89 3 13.42 3H10.98C10.51 3 10.1 3.19 9.83 3.53C9.57 3.85 9.45 4.28 9.49 4.76C8.81 4.97 8.15 5.25 7.53 5.6L7.43 5.65C6.27 6.32 5.26 7.21 4.42 8.3C2.96 10.19 2.16 12.57 2.16 14.99V17.64H2.01C1.45 17.64 1 18.12 1 18.7V20.34C1 20.92 1.45 21.4 2.01 21.4H22.39C22.95 21.4 23.4 20.92 23.4 20.34V18.7C23.4 18.42 23.3 18.16 23.11 17.96ZM14.72 6.07C15.13 6.2 15.54 6.37 15.93 6.57L15.23 11.23C15.18 11.58 15.41 11.92 15.75 11.98H15.85C16.16 11.98 16.43 11.75 16.47 11.43L17.1 7.28C19.5 9.01 20.98 11.94 20.98 14.99V15.25H19.06C18.38 15.25 17.83 15.83 17.83 16.54V17.64H12.83V12.37C13.42 12.29 13.86 11.84 13.95 11.2L14.72 6.07ZM20.98 16.56V17.64H19.09V16.56H20.98ZM10.79 4.37C10.82 4.33 10.89 4.31 10.98 4.31H13.42C13.51 4.31 13.57 4.33 13.61 4.37C13.64 4.42 13.68 4.53 13.65 4.69L12.71 11C12.7 11.07 12.68 11.08 12.62 11.08H11.78C11.72 11.08 11.7 11.07 11.69 11L10.75 4.69C10.72 4.53 10.75 4.42 10.79 4.37ZM3.42 14.99C3.42 11.94 4.9 9.01 7.3 7.28L7.93 11.43C7.97 11.75 8.24 11.98 8.55 11.98H8.65C8.99 11.92 9.22 11.58 9.17 11.23L8.47 6.57C8.86 6.37 9.27 6.2 9.68 6.07L10.45 11.2C10.54 11.84 10.98 12.29 11.57 12.37V17.64H6.57V16.54C6.57 15.83 6.02 15.25 5.34 15.25H3.42V14.99ZM3.42 16.56L5.31 16.54V17.64H3.42V16.56ZM22.14 20.09H2.26V18.95H22.14V20.09Z" fill={fill || 'black'} />
    </svg>
  )
}

HelmetIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}

export const SafetyHelmetIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill || 'black'} d="M58 51H6a4 4 0 0 1 0-8h52a4 4 0 0 1 0 8ZM6 45a2 2 0 0 0 0 4h52a2 2 0 0 0 0-4Z" />
      <path fill={fill || 'black'} d="M57 45H7a1 1 0 0 1-1-1V33A26 26 0 0 1 27.26 7.44a1 1 0 0 1 .78.18 1 1 0 0 1 .4.7L29.91 23h4.18l1.47-14.68a1 1 0 0 1 .4-.7 1 1 0 0 1 .78-.18 25.87 25.87 0 0 1 13.65 7.17c.29.3.58.6.86.91A25.92 25.92 0 0 1 58 33v11a1 1 0 0 1-1 1ZM8 43h48V33a23.93 23.93 0 0 0-6.23-16.14c-.26-.29-.53-.57-.8-.83a23.83 23.83 0 0 0-11.53-6.41L36 24.1a1 1 0 0 1-1 .9h-6a1 1 0 0 1-1-.9L26.56 9.62A24 24 0 0 0 8 33Z" />
      <path fill={fill || 'black'} d="M35 25h-6a1 1 0 0 1-1-.9l-2-20a1 1 0 0 1 .26-.77A1 1 0 0 1 27 3h10a1 1 0 0 1 .74.33 1 1 0 0 1 .26.77l-2 20a1 1 0 0 1-1 .9Zm-5.09-2h4.18L35.9 5h-7.8ZM18 33a1 1 0 0 1-1-.78l-3.7-16.66a1 1 0 1 1 2-.44L19 31.78a1 1 0 0 1-.76 1.2ZM47 33h-.22a1 1 0 0 1-.76-1.2L49.53 16a1 1 0 1 1 2 .44L48 32.22a1 1 0 0 1-1 .78Z" />
    </svg>
  )
}

SafetyHelmetIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}