import React, { Component } from "react";
import can_sms from "./images/sms.png";
import cannot_sms from "./images/nosms.png";
class CreateEvent extends Component {
  state = {
    selectedButton: {
      everyone: false,
      noone: true,
      wardens: false
    }
  };

  renderEventTypes() {
    if (
      this.props.activeEvent !== undefined &&
      this.props.activeEvent !== null
    ) {
      let eventType = this.props.activeEvent;
      return (
        <option
          value={eventType.id}
          key={
            eventType.id + " " + eventType.type + " " + eventType.description
          }
        >
          {eventType.type} - Current Event
        </option>
      );
    } else {
      return this.props.eventTypes.map(eventType => {
        return (
          <option
            value={eventType.id}
            key={
              eventType.id + " " + eventType.type + " " + eventType.description
            }
          >
            {eventType.type}
          </option>
        );
      });
    }
  }

  renderInitiateEventButton = () => {
    return this.props.initiateEventBtnEnable !== undefined &&
      this.props.initiateEventBtnEnable === true ? (
      <button
        className="event__initiateButton mt-10 btn__initiateButton--active"
        onClick={this.props.showModal}
      >
        Initiate Event
      </button>
    ) : (
      <button className="event__initiateButton mt-10" disabled>
        Initiate Event
      </button>
    );
  };

  handleTypeChange = e => {
    const type = this.props.eventTypes.find(type => {
      return type.id === parseInt(e.target.value, 10);
    });
    this.props.changeType(parseInt(e.target.value, 10), type.message);
  };

  handleMessageChange = e => {
    this.props.changeMessage(e.target.value);
  };

  handleAudienceChange = e => {
    const buttonText = e.target.innerText.toLowerCase();
    if (buttonText === "everyone" && this.props.sms_allowed) {
      this.setState({
        ...this.state,
        selectedButton: {
          ...this.state.selectedButton,
          everyone: true,
          noone: false,
          wardens: false
        }
      });
    } else if (buttonText === "no one") {
      this.setState({
        ...this.state,
        selectedButton: {
          ...this.state.selectedButton,
          everyone: false,
          noone: true,
          wardens: false
        }
      });
    } else if (buttonText === "wardens" && this.props.sms_allowed) {
      this.setState({
        ...this.state,
        selectedButton: {
          ...this.state.selectedButton,
          everyone: false,
          noone: false,
          wardens: true
        }
      });
    }
    this.props.changeAudience(e.target.innerText);
  };

  renderEveryoneButton = () => {
    return this.state.selectedButton.everyone === true ? (
      <button
        className="btn-flat btn__toggle btn__toggle--active"
        onClick={this.handleAudienceChange}
        disabled={!this.props.sms_allowed}
      >
        Everyone
      </button>
    ) : (
      <button
        className="btn-flat btn__toggle"
        onClick={this.handleAudienceChange}
        disabled={!this.props.sms_allowed}
      >
        Everyone
      </button>
    );
  };

  renderNooneButton = () => {
    return this.state.selectedButton.noone === true ? (
      <button
        className="btn-flat btn__toggle btn__toggle--active"
        onClick={this.handleAudienceChange}
      >
        No One
      </button>
    ) : (
      <button
        className="btn-flat btn__toggle"
        onClick={this.handleAudienceChange}
      >
        No one
      </button>
    );
  };

  renderWardensButton = () => {
    return this.state.selectedButton.wardens === true ? (
      <button
        className="btn-flat btn__toggle btn__toggle--active"
        onClick={this.handleAudienceChange}
        disabled={!this.props.sms_allowed}
      >
        wardens
      </button>
    ) : (
      <button
        className="btn-flat btn__toggle"
        onClick={this.handleAudienceChange}
        disabled={!this.props.sms_allowed}
      >
        wardens
      </button>
    );
  };

  renderCanSms = () => {
    let image_src = this.props.sms_allowed ? can_sms : cannot_sms;
    return <img src={image_src} alt="sms_status" />;
  };

  render() {
    return (
      <div className="row mt-20">
        <div className="col s12">
          <label className="event__createForm--label event__typeSelect">
            Create Event:
            <select
              className="browser-default mt-10"
              defaultValue="default"
              onChange={this.handleTypeChange}
            >
              <option value="default" disabled>
                {this.props.loading === true
                  ? "Loading..."
                  : "Select Event Type"}
              </option>
              {this.renderEventTypes()}
            </select>
          </label>
        </div>

        <div className="col s12">
          <label className="event__createForm--label event__message">
            Default Message:
            <textarea
              className="event__message--textarea mt-10"
              onChange={this.handleMessageChange}
              value={this.props.input.message}
            />
          </label>
        </div>

        <div className="col s12">
          <label className="event__createForm--label event__audience">
            Message Audience:{this.renderCanSms()}
            <div className="event__audienceButtonGroup mt-10">
              {this.renderEveryoneButton()}
              {this.renderNooneButton()}
              {this.renderWardensButton()}
            </div>
          </label>
        </div>

        <div className="col s12 mt-40">{this.renderInitiateEventButton()}</div>
      </div>
    );
  }
}

export default CreateEvent;
