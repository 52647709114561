import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const UnReadIcon = ({ width, height, fill }) => {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '306'}
			height={height || '308'}
			overflow="hidden"
			viewBox="0 0 307 308"
			fill={fill || 'black'}
		>
			<path
				fillRule="evenodd"
				d="M2761.11 1629.26c11.43 4.76 24.6 2.14 33.36-6.64l31.88-31.93h170.9c28.16 0 50.99-22.92 50.99-51.17v-163.75c0-28.25-22.83-51.16-50.99-51.16h-204.01c-28.17 0-51 22.91-51 51.16v225.15c0 12.4 7.44 23.6 18.87 28.34zm11.73-69.27v-184.22c0-11.3 9.14-20.46 20.4-20.46h204.01c11.26 0 20.39 9.16 20.39 20.46v163.75c0 11.3-9.13 20.47-20.39 20.47h-183.6l-40.81 40.93z"
				transform="translate(-2742 -1324)"
			></path>
			<path
				d="M2889.7 1381h22.6l-1.88 113.78h-18.84L2889.7 1381zM2917 1523c0 8.836-7.164 16-16 16-8.837 0-16-7.164-16-16 0-8.837 7.163-16 16-16 8.836 0 16 7.163 16 16z"
				transform="translate(-2742 -1324)"
			></path>
    </svg>
  )
}

UnReadIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
