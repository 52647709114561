import React, { Component } from "react";
import {
  CloseIcon,
  SearchIcon
} from "../../Icons";

class FloorSearch extends Component {
  state = {
    floorSearchText: "",
    showFloorSelector: false
  };

  toggleFloorSelector = () => {
    this.setState({
      ...this.state,
      showFloorSelector: !this.state.showFloorSelector
    });
  };

  updateFloorSearchText = e => {
    this.setState({
      ...this.state,
      floorSearchText: e.target.value
    });
  };

  renderFilteredFloorList = () => {
    return this.props.floors.map(item => {
      let bldgName = item.building.name.toLowerCase();
      return item.floors
        .filter(floor => {
          let floorName = floor.name.toLowerCase();
          let bldgFloorName = bldgName + " / " + floorName;
          return floorName.includes(this.state.floorSearchText.toLowerCase()) ||
            bldgName.includes(this.state.floorSearchText.toLowerCase()) ||
            bldgFloorName.includes(this.state.floorSearchText.toLowerCase()) ||
            this.state.floorSearchText.length === 0
            ? floor
            : null;
        })
        .map(floor => {
          let floorName = floor.name.toLowerCase();
          return (
            <div
              className="col s12"
              key={"floorSearch--" + item.building.id + floor.id}
            >
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  value={floor.id}
                  onChange={e =>
                    this.props.handleFloorSelectionChange(floor.id.toString())
                  }
                  checked={this.props.isFloorSelected(floor.id.toString())}
                />
                <span>
                  <b>{bldgName}</b>
                  {" / " + floorName}
                </span>
              </label>
            </div>
          );
        });
    });
  };

  renderFloorSelector = () => {
    if (this.state.showFloorSelector) {
      return (
        <React.Fragment>
          <div
            className="evacuee__areaoptions--overlay"
            onClick={this.toggleFloorSelector}
          />
          <div className="evacsafety__floorSelector--popup">
            <div className="row evacsafety__floorSelector--popupSearch">
              <div className="col s12">
                <input
                  type="text"
                  className="browser-default"
                  placeholder="Search.."
                  value={this.state.floorSearchText}
                  onChange={e => this.updateFloorSearchText(e)}
                />
              </div>
            </div>
            <div className="row evacsafety__floorSelector--popupResults">
              <div className="col s12">
                <label>
                  <input
                    type="checkbox"
                    className="filled-in"
                    value="all"
                    onChange={e => this.props.handleFloorSelectionChange("all")}
                    checked={this.props.isFloorSelected("all")}
                  />
                  <span>
                    <b>All</b>
                  </span>
                </label>
              </div>
              {this.props.floors.length === 0 ? (
                <div className="col s12 center">
                    <b>No Floors</b>
                </div>
              ) : (
                this.renderFilteredFloorList()
              )}
            </div>
          </div>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  renderFloorSelection = () => {
      if(this.props.selectedFloors.includes("all")) {
          return <span>All</span>;
      }else{
          return "Multi";
      }
  }

  renderFloorText = () => {
    if(this.props.selectedFloors.includes("all")) {
      return <span>Floors</span>;
    }else{
      return "Floors";
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.renderFloorSelector()}
        <div className="evacsaftey__search">
          <div className="col s3 hidden">
            <div
              className="evacsafety__floorSelector"
              onClick={this.toggleFloorSelector}
            >
              <div className="left">
                <div className="evacsafety__floorSelecor--label">{this.renderFloorText()}</div>
                <div className="evacsafety__floorSelector--indicator">{this.renderFloorSelection()}</div>
              </div>
              <div className="right">
                <i className="material-icons">arrow_drop_down</i>
              </div>
            </div>
          </div>
          <div className="search__bgoverlay" />
          <div className="search__field">
            <span className="search__icon--magnifying-glass">
              <SearchIcon width="20px" height="20px" fill="#aaa" />
            </span>
            <input
              type="text"
              ref="searchInput"
              className="search__input browser-default"
              value={this.props.searchText}
              onChange={e => {
                this.props.updateSearchText(e);
              }}
            />
            {!this.props.disableSearchClear() ?
              <button
                className="search__clear"
                onClick={this.props.clearSearch}
                disabled={this.props.disableSearchClear()}
              >
                <CloseIcon width="12px" height="12px" fill={'black'} />
              </button>
            : '' }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FloorSearch;
