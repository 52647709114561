import React, { Component } from "react";
import { withRouter } from "react-router-dom";

class EmailLogin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credentials: {
        email: "",
        password: ""
      }
    };
  }

  handleInputChange = event => {
    this.setState({
      ...this.state,
      credentials: {
        ...this.state.credentials,
        [event.target.name]: event.target.value
      }
    });
  };

  login = event => {
    event.preventDefault();
    this.props.login(this.state.credentials);
  };

  renderButton = () => {
    let button = null;
    if (this.props.loading === true) {
      button = (
        <button
          className="login__form--button waves-effect btn light-blue no-uppercase"
          onClick={this.login}
          disabled
        >
          <i className="material-icons right">loop</i>Loading
        </button>
      );
    } else {
      button = (
        <button
          className="login__form--button waves-effect btn light-blue no-uppercase"
          onClick={this.login}
        >
          <i className="material-icons right">vpn_key</i>Sign In
        </button>
      );
    }
    return button;
  };

  render() {
    return (
      <form className="col s12">
        <div className="row mt-10">
          <div className="input-field col s12">
            <i className="material-icons prefix">account_circle</i>
            <input
              type="text"
              name="email"
              id="email"
              className="login__form--textbox"
              value={this.state.email}
              onChange={this.handleInputChange}
            />
            <label htmlFor="email">Email</label>
          </div>
          <div className="input-field col s12">
            <i className="material-icons prefix">lock</i>
            <input
              type="password"
              name="password"
              id="password"
              className="login__form--textbox"
              value={this.state.password}
              onChange={this.handleInputChange}
            />
            <label htmlFor="password">Password</label>
          </div>
          <div className="input-field col s12">{this.renderButton()}</div>
        </div>
      </form>
    );
  }
}

export default withRouter(EmailLogin);
