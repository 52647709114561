import React, { Component } from "react";
import {Link} from "react-router-dom";
import {route} from "../../Routes";

import { AREA, FILTER, MESSAGE } from "./index";
import { ArrowChevronIcon } from "../../Icons";

import {
  AreasIcon,
  CloseIcon,
  PeepIcon,
  BroadcastIcon,
  NoReplyIcon,
  ShowAllIcon,
  UnReadIcon,
  VisitorIcon
} from "../../Icons";

const AR = "area";
const MSG = "message";

class FilterControl extends Component {
  state = {
    area: AREA.MYAREA,
    showAreaOptions: false,
    toggleFloorbyId: 0,
  };

  toggleAreaOptions = () => {
    this.setState({
      ...this.state,
      showAreaOptions: !this.state.showAreaOptions
    });
  };

  setArea = area => {
    this.setState(
      {
        ...this.state,
        area
      },
      () => {
        this.props.setFilter(AR, this.state.area);
      }
    );
  };

  handleAreaChange = event => {
    switch (event.target.value) {
      case AREA.MYAREA:
        this.setArea(AREA.MYAREA);
        break;
      case AREA.EVERYONE:
        if (this.state.area !== null && this.state.area === AREA.EVERYONE) {
          this.setArea(AREA.MYAREA);
        } else {
          //check if
          this.setArea(AREA.EVERYONE);
        }
        break;
      default:
        //check if the state already has a building
        var bldgId = event.target.value;
        if (this.state.area instanceof Array) {
          var area = [...this.state.area];
          //if so check if the value is duplicate
          var idx = area.indexOf(bldgId);
          if (idx > -1) {
            //if so delete the duplicate value from the state array
            area.splice(idx, 1);
          } else {
            //if not insert the new value into the state array
            area.push(bldgId);
          }
        } else {
          //if area state selects all buildings
          //then when a building checkbox is clicked
          //uncheck only that building
          if (this.state.area === AREA.EVERYONE) {
            area = [];
            this.props.buildings.forEach(bldg => {
              if (bldgId !== bldg.buildingId.toString()) {
                area.push(bldg.buildingId.toString());
              }
            });
          } else {
            area = [event.target.value];
          }
        }
        //check to see if all the buildings are checked
        if (this.props.buildings.length === area.length) {
          //if so change the area to everyone
          area = AREA.EVERYONE;
        } else if (area.length === 0) {
          area = AREA.MYAREA;
        }
        this.setArea(area);

        break;
    }
  };

  renderAreaBtn = () => {
    let onActive = this.state.showAreaOptions ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.toggleAreaOptions();
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          <AreasIcon width="35" height="28" />
        </div>
        <div className="evacsafety__menu-btn-label">
          Areas
        </div>
      </div>
    );
  };

  renderShowAllBtn = () => {
    let name = this.props.show.message;
    let icon = null;
    let changeTo = null;

    if (name === MESSAGE.SHOWALL) {
      icon = <ShowAllIcon width="25" height="30" />;
      changeTo = MESSAGE.UNREAD;
    } else if (name === MESSAGE.UNREAD) {
      icon = <UnReadIcon width="25" height="30" />;
      changeTo = MESSAGE.NOREPLY;
    } else if (name === MESSAGE.NOREPLY) {
      icon = <NoReplyIcon width="25" height="30" />;
      changeTo = MESSAGE.SHOWALL;
    }

    let onActive = this.props.show.message ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(MSG, changeTo);
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          {icon}
        </div>
        <div className="evacsafety__menu-btn-label">
          {name}
        </div>
        <div className="evacsafety__menu-btn-boba">
          <span className={`boba ${changeTo === MESSAGE.UNREAD ?  'on--active' : ''}`}></span>
          <span className={`boba ${changeTo === MESSAGE.NOREPLY ? 'on--active' : ''}`}></span>
          <span className={`boba ${changeTo === MESSAGE.SHOWALL ? 'on--active' : ''}`}></span>
        </div>
      </div>
    );
  };

  renderAssistBtn = () => {
    let onActive = this.props.show.assist ? "on--active" : "";

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(FILTER.ASSIST, !this.props.show.assist);
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          <PeepIcon width="25" height="30" />
        </div>
        <div className="evacsafety__menu-btn-label">
          Assist
        </div>
      </div>
    );
  };

  renderVisitorBtn = () => {
    let onActive = this.props.show.visitor ? "on--active" : "";
    let onActiveIcon = this.props.show.visitor ? <VisitorIcon width="38" height="28" fill="black" /> : <VisitorIcon width="38" height="28" fill="white" />;

    return (
      <div
        className={`evacsafety__menu-btn ${onActive}`}
        onClick={() => {
          this.props.setFilter(FILTER.VISITOR, !this.props.show.visitor);
        }}
      >
        <div className="evacsafety__menu-btn-icon">
          {onActiveIcon}
        </div>
        <div className="evacsafety__menu-btn-label">
          Visitor
        </div>
      </div>
    );
  };

  canMessageAll = event => {
    if(this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      event.preventDefault();
    }
  }

  renderMessageAllBtn = () => {

    let className = "evacsafety__menu-btn";
    if(this.props.evacuees.length === 0 || !this.props.sms_allowed) {
      className = className +" "+ className+"--disabled";
    }

    return (
      <div className={className}>
        <Link to={{
          pathname: route.message.evacueegroup.link,
          state: {
            filters: this.props.filters,
            searchText: this.props.searchText,
            sms_allowed: this.props.sms_allowed
          }
        }}
        onClick={this.canMessageAll}
        className="text-white"
        >
          <div className="evacsafety__menu-btn-icon">
            <BroadcastIcon width="30" height="30" />
          </div>
          <div className="evacsafety__menu-btn-label">
            Broadcast
          </div>
        </Link>
      </div>
    );
  };

  toggleFloor = (e) => {
    this.setState({
      toggleFloorbyId: this.state.toggleFloorbyId === e ? 0 : e
    });
  }

  renderFloor = () => {
    return this.props.floors.map(item => {
      return item.floors
        .map(floor => {
          return (
            <li
              className="evacuee__areaoptions--listitem listitem-floor"
              key={"floorSearch--" + item.building.id + floor.id}
            >
              <label className="item-label">
                <input
                  type="checkbox"
                  className="filled-in"
                  value={floor.id}
                  onChange={e =>
                    this.props.handleFloorSelectionChange(floor.id.toString())
                  }
                  checked={this.props.isFloorSelected(floor.id.toString())}
                />
                <span className="item-overlay" />
                <span className="item-text">
                  {floor.name}
                </span>
              </label>
            </li>
          );
        });
    });
  };

  renderAreaOptions = () => {
    return this.state.showAreaOptions ? (
      <React.Fragment>
        <div
          className="evacuee__areaoptions--overlay"
          onClick={this.toggleAreaOptions}
        />
        <div className="evacuee__areaoptions">
          <div className="evacuee__areaoptions-head">
            <h2>Select Building/Floors</h2>
            <button onClick={this.toggleAreaOptions}>
              <CloseIcon width={20} height={20} fill="white" />
            </button>
          </div>
          {/* ---------------- BEGIN FILTER ---------------- */}
          <ul className="evacuee__areaoptions--list">
            <h4 className="evacuee__areaoptions--list-title">
              My Area
            </h4>
            <li className="evacuee__areaoptions--listitem listitem-myarea">
              <label className="item-label">
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.MYAREA}
                  onChange={e => this.handleAreaChange(e)}
                  checked={this.state.area === AREA.MYAREA ? true : false}
                />
                <span className="item-overlay" />
                <span className="item-text">
                  {this.props.areas &&
                    this.props.areas.map((item, key) => {
                      return <p key={key}>{item}</p>;
                    }
                  )}
                </span>
              </label>
            </li>

            <li className="evacuee__areaoptions--listitem hidden">
              <label className="item-label">
                <input
                  type="checkbox"
                  className="filled-in"
                  value={AREA.EVERYONE}
                  onChange={e => this.handleAreaChange(e)}
                  checked={this.state.area === AREA.EVERYONE ? true : false}
                />
                <span className="item-overlay" />
                <span className="item-text">
                  All Buildings
                </span>
              </label>
            </li>

            <h4 className="evacuee__areaoptions--list-title">
              Buildings & Floor
            </h4>
            <div className="evacuee__areaoptions--childlist">
              {this.props.buildings.map(building => {
                return (
                  <div
                    className="evacuee__areaoptions--childlistitem-wrapper"
                    key={building.buildingId + " bldg"}
                  >
                    <div className="evacuee__areaoptions--childlistitem childlistitem--building">
                      <label className="item-label">
                        <input
                          type="checkbox"
                          className="filled-in"
                          value={building.buildingId}
                          onChange={e => this.handleAreaChange(e)}
                          checked={
                            (this.state.area instanceof Array &&
                              this.state.area.indexOf(
                                building.buildingId.toString()
                              ) > -1) ||
                              this.state.area === AREA.EVERYONE
                              ? true
                              : false
                          }
                        />
                        <span className="item-overlay" />
                        <span className="item-text">
                          {building.name}
                        </span>
                      </label>
                      {/* BUTTON ARROW (Check if building have floors then appear the arrow) */}
                      {this.props.floors.map((item, idx) => {
                        return (
                          building.buildingId === item.building.id ?
                            <div className="btn-collapse-box" key={idx}>
                              <button
                                key={item.building.id}
                                className="btn-collapse"
                                onClick={() => {
                                  this.toggleFloor(building.buildingId);
                                }}
                              >
                                {this.state.toggleFloorbyId === building.buildingId ?
                                  <ArrowChevronIcon direction="up" width={32} height={32} />
                                  :
                                  <ArrowChevronIcon width={32} height={32} />
                                }
                              </button>
                            </div> : ''
                          )
                        })
                      }
                      {/* END BUTTON ARROW */}
                    </div>

                    {/* FLOOR */}
                    {this.props.floors.length === 0 ? (
                      <div className="col s12 center">
                        <b>No Floors</b>
                      </div>
                    ) : (
                      <>
                        {this.state.toggleFloorbyId === building.buildingId && (
                          <div className="floors-scroll">
                            <ul className="floors-list">
                              {this.props.floors.map(item => {
                                return item.floors
                                .map(floor => {
                                  return (
                                    building.buildingId === item.building.id ?
                                      <li
                                        className="evacuee__areaoptions--listitem listitem-floor"
                                        key={"floorSearch--" + item.building.id + floor.id}
                                      >
                                        <label className="item-label">
                                          <input
                                            type="checkbox"
                                            className="filled-in"
                                            value={floor.id}
                                            onChange={e =>
                                              this.props.handleFloorSelectionChange(floor.id.toString())
                                            }
                                            checked={this.props.isFloorSelected(floor.id.toString())}
                                          />
                                          <span className="item-overlay" />
                                          <span className="item-text">
                                            {floor.name}
                                          </span>
                                        </label>
                                      </li>
                                      : ''
                                    )
                                  }
                                )
                              })}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                    {/* END FLOOR */}
                  </div>
                );
              })}
            </div>
          </ul>
          {/* ---------------- END FILTER ---------------- */}
        </div>
      </React.Fragment>
    ) : (
      false
    );
  };

  render() {
    return (
      <div className="evacsafety__menu">
        {this.renderAreaBtn()}
        {this.renderAreaOptions()}
        {this.renderAssistBtn()}
        {this.renderVisitorBtn()}
        {this.renderShowAllBtn()}
        {this.renderMessageAllBtn()}
      </div>
    );
  }
}

export default FilterControl;
