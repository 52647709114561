import React from "react";
import { ResponseRequiredIcon } from "../../../Icons";

const MessageTemplateItem = props => {
  return (
    <div
      className="col s12 messagebox__templateItem"
      onClick={() => props.insertTemplate(props.template.id)}
    >
      <div className="box">
        {props.template.automated_response_message === 1 && (
          <div className="box-icon">
            <ResponseRequiredIcon width="20px" height="20px" fill={'#EB2A31'} />
          </div>
        )}
        <div className="box-text">
          {props.template.text}
        </div>
      </div>
    </div>
  );
};

export default MessageTemplateItem;
