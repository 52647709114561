import React, { Component } from "react";
import WardenDot, { DOT_TYPE } from "./wardendot";
import WardenListPopup from "./WardenListPopup";

class Floor extends Component {
  state = {
    showPopup: false
  };

  togglePopup = () => {
    this.setState({ showPopup: !this.state.showPopup });
  };

  renderSpecificDot = type => {
    return this.props.wardens.map(warden => {
      if (warden.status.toString().toLowerCase() === type) {
        return (
          <WardenDot
            key={warden.id + " " + warden.name + " dot"}
            status={warden.status}
          />
        );
      } else {
        return null;
      }
    });
  };

  renderWardenDots = () => {
    return (
      <React.Fragment>
        {this.renderSpecificDot(DOT_TYPE.ODT)}
        {this.renderSpecificDot(DOT_TYPE.UKN)}
        {this.renderSpecificDot(DOT_TYPE.NA)}
        {this.renderSpecificDot(DOT_TYPE.OFDT)}
      </React.Fragment>
    );
    // return this.props.wardens.map(warden => {
    //   return (
    //     <WardenDot
    //       key={warden.id + " " + warden.name + " dot"}
    //       status={warden.status}
    //     />
    //   );
    // });
  };

  render() {
    let floorClass = "col s7 floor__name";
    if (this.state.showPopup) {
      floorClass += " floor-active";
    }
    return (
      <React.Fragment>
        <div className="row floor">
          <div className={floorClass} onClick={this.togglePopup}>
            {this.props.name}
          </div>
          <div className="col s5 floor__wardens">{this.renderWardenDots()}</div>
        </div>
        {this.state.showPopup ? (
          <WardenListPopup
            wardens={this.props.wardens}
            closePopup={this.togglePopup}
            warden_id={this.props.warden_id}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default Floor;
