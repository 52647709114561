import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const BroadcastIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '268'}
      height={height || '196'}
      overflow="hidden"
      fill={fill || 'black'}
      viewBox="0 0 268 196"
    >
      <path d="M201.1,51c-2,0-3.9-1.2-4.7-3.1c-1.1-2.6,0.1-5.7,2.7-6.8l52.8-22.7c2.6-1.1,5.6,0.1,6.7,2.7s-0.1,5.7-2.7,6.8
	l-52.8,22.7C202.5,50.9,201.8,51,201.1,51z"/>
      <path d="M253.9,158c-0.7,0-1.3-0.1-2-0.4l-52.8-22.7c-2.6-1.1-3.8-4.1-2.7-6.8c1.1-2.6,4.1-3.8,6.7-2.7l52.8,22.7
	c2.6,1.1,3.8,4.1,2.7,6.8C257.8,156.8,255.9,158,253.9,158z"/>
      <path d="M262.9,93C262.9,93,262.8,93,262.9,93l-61.8-0.7c-2.8,0-5.1-2.4-5.1-5.2c0-2.8,2.5-5.1,5.2-5.1l61.8,0.7
	c2.8,0,5.1,2.4,5.1,5.2C268,90.7,265.7,93,262.9,93z"/>
      <path d="M166.9,177c-1.1,0-2.2-0.4-3.1-1c-54-40.3-111-42.4-121.9-42.4c-1.4,0-2.2,0-2.3,0c-2.2,0.1-4.3-1.2-5-3.3
	C17.8,85.4,34,47.2,34.7,45.6c0.8-1.9,2.6-3.1,4.6-3.1c68.9-1,106.3-28.3,124.4-41.5c1.6-1.1,3.6-1.3,5.4-0.4
	c1.7,0.9,2.8,2.6,2.8,4.6c0,10.1,0,50.1,0.1,88.5c0,35.7,0.1,70.1,0.1,78.2c0,1.9-1.1,3.7-2.8,4.6C168.4,176.8,167.7,177,166.9,177z
	 M43,123.3c13.2,0.1,66.3,3.1,118.7,38.6c0-14.6,0-41-0.1-68.2c0-30.4-0.1-61.8-0.1-78.6C140.8,29.6,103.9,51,43,52.7
	C39.9,61.8,32,90.6,43,123.3z"/>
      <path d="M39.5,134l-11.1-0.2C12.5,133.8,0,113.6,0,87.9S12.5,42,28.5,42H41v10.4H28.5c-8.5,0-18,14.6-18,35.5
	c0,20.9,9.5,35.5,18,35.5l11.2,0.2L39.5,134z"/>
      <path d="M67.5,196c-9.1,0-16.5-7.3-16.5-16.2V124l33,4.7v51.1C84,188.7,76.6,196,67.5,196z M61.6,136v43.8
	c0,3.2,2.7,5.8,5.9,5.8s5.9-2.6,5.9-5.8v-42.1L61.6,136z"/>
    </svg>
  )
}

BroadcastIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
