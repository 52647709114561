import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../../Routes";
import { MessageIcon, PhoneIcon } from "../../../Icons";
import msg_unread_img from "../../Template/images/msg_unread.png";

class WardenList extends Component {

  componentDidMount() {}

  beLowercase = (e) => {
    if (e) {
      return e.replace(/\s+/g, '').toLowerCase();
    } else {
      return null
    }
  }

  renderArea = () => {
    return (
      this.props.info.areas !== undefined &&
      this.props.info.areas.length > 0 &&
      this.props.info.areas.map(location => {
        return (
          <p
            key={this.props.info.id + location.id + this.props.info.role}
          >
            {location.name}
          </p>
        );
      })
    );
  };

  wardenList = () => {
    return (
      <div className="card__messengerlist" key={this.props.info.id}>
        <div className={`card__messengerlist-left ${this.props.type === 'broadcast' ? 'w-full' : ''}`}>
          <div className={`card__messengerlist-bar color--${this.beLowercase(this.props.info.status)}`} />
          <div className="card__messengerlist-caption">
            {this.props.info.name && (
            <h2 className="card__messengerlist-caption-name">{this.props.info.name}</h2>
            )}
            {this.props.info.role && (
            <h3 className="card__messengerlist-caption-role">{this.props.info.role}</h3>
            )}
            <div className="card__messengerlist-caption-areas">
              {
                this.props.info.areas !== undefined &&
                this.props.info.areas.length > 0 &&
                  this.props.info.areas.map((location, key) => (
                  <p key={key}>
                    {location.name}
                  </p>
              ))}
            </div>
          </div>
        </div>
        <div className={`card__messengerlist-right ${this.props.type === 'broadcast' ? 'hidden' : ''}`}>
          <div className="cta">
            {this.props.info.unread && (
              <div className="icon__unread">
                <img src={msg_unread_img} alt="unread messages" />
              </div>
            )}
            <div className="btn-message">
              <Link
                to={
                  route.message.warden.link +
                  "/" +
                  this.props.info.id +
                  "?from=" +
                  route.message.all.link
                }
              >
                <MessageIcon width="25px" height="25px" fill="white" />
              </Link>
            </div>
            <div className="btn-phone">
              <a href={`tel:${this.props.info.phone}`}>
                <PhoneIcon width="25px" height="25px" fill="white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return this.wardenList()
  }
}

export default WardenList;
