import React, { Component } from "react";
import WardenList from "./WardenList";

class MessengerList extends Component {
  renderMessengerListItems = () => {
    return (
      this.props.list && this.props.list.length > 0 ?
        this.props.list
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((warden, idx) => {
          return (
            <WardenList
              key={idx}
              info={warden}
              type={this.props.type}
            />
          )
        }
      ) :
      <div className="not-found">
        <p className="not-found-title">No Wardens Found</p>
      </div>
    );
  };

  render() {
    return (
      <div className="container container-messenger-list">
        {this.renderMessengerListItems()}
      </div>
    );
  }
}

export default MessengerList;
