import React from "react";
import { Link } from "react-router-dom";
import { route } from "../../Routes";
import { MessageIcon } from "../../Icons";

const ActionBar = props => {
  const checkDisabled = event => {
    if (props.audienceCount === 0 || props.isAllMessageDisabled()) {
      event.preventDefault();
      return true;
    }
    else {
      return false;
    }
  };

  return (
    <div className="col-row">
      <div className="col-left items-center-msg">
        Message
      </div>
      <div className="col-right row-message">
        <div className="box-counter">
          <span className="box-counter-text">Count</span>
          <span className="box-counter-amount">{props.audienceCount}</span>
        </div>
        {props.audienceCount > 0 || !props.isAllMessageDisabled() ?
          <div className="box-broadcast">
            <Link
              onClick={e => checkDisabled(e)}
              to={{
                pathname: route.message.group.link,
                state: {
                  audience: props.audience,
                  sms_allowed: props.sms_allowed
                }
              }}
            >
              <span>Broadcast</span>
              <MessageIcon width={22} height={22} fill="white" />
            </Link>
          </div>
        : null}
        <div className="box-clear">
          <button
            onClick={props.clearFilters}
          >
            Clear
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
