import React, { Component } from "react";

class PeepPopUp extends Component {
  render() {
    if (this.props.show === true) {
      return (
        <div className="PeepPopUp__Overlay" onClick={this.props.close}>
          <div className="PeepPopUp">
            <div className="row PeepPopUp__Header">
              <div className="col s12">
                <span className="PeepPopUp__HeaderIcon left">
                  <i className="material-icons">accessible</i>
                </span>
                <span className="PeepPopUp__HeaderName right truncate">
                  {this.props.name}
                </span>
              </div>
            </div>
            <div className="container PeepPopUp__Content">
              <div className="row">
                <div className="col s12">Disability or Mobility Impairment</div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="PeepPopUp__box">
                    {this.props.details.disability}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12">Assistance Needed</div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="PeepPopUp__box">
                    {this.props.details.assistance}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col s12">Other Details</div>
              </div>
              <div className="row">
                <div className="col s12">
                  <div className="PeepPopUp__box">
                    {this.props.details.other}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default PeepPopUp;
