import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const CloseIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill || 'black'} d="M20.373 19.3335C20.373 19.4668 20.3463 19.6002 20.293 19.7335C20.2397 19.8535 20.173 19.9735 20.0663 20.0668C19.973 20.1735 19.853 20.2402 19.733 20.2935C19.613 20.3468 19.4797 20.3735 19.3597 20.3735H19.333C19.1997 20.3602 19.0663 20.3335 18.933 20.2802C18.813 20.2268 18.7063 20.1468 18.613 20.0402L10.333 11.7735L2.05301 20.0535C1.85301 20.2402 1.61301 20.3335 1.35967 20.3335C1.06634 20.3335 0.826341 20.2268 0.639674 20.0402C0.439674 19.8402 0.333008 19.6002 0.333008 19.3335C0.333008 19.0668 0.426341 18.8135 0.613008 18.6268L8.89301 10.3335L0.613008 2.0535C0.426341 1.8535 0.333008 1.60016 0.333008 1.3335C0.333008 1.06683 0.439674 0.826829 0.626341 0.626829C0.826341 0.440163 1.06634 0.333496 1.33301 0.333496C1.61301 0.333496 1.85301 0.426829 2.03967 0.613496L10.333 8.8935L18.613 0.613496C18.813 0.426829 19.053 0.333496 19.3197 0.333496C19.5997 0.333496 19.8397 0.440163 20.0263 0.626829C20.2263 0.826829 20.333 1.06683 20.333 1.3335C20.333 1.60016 20.2397 1.8535 20.053 2.04016L11.773 10.3335L20.053 18.6135C20.1463 18.7068 20.2263 18.8135 20.2797 18.9468C20.333 19.0535 20.3597 19.2002 20.373 19.3335Z" />
    </svg>
  )
}

CloseIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
