import React from "react";

const CreateEventNotice = props => {
  if (props.show === true) {
    return (
      <div className="CEN__container">
        <div className="CEN__type"> ! </div>
        <div className="CEN__message">
          There are no current Events at your Site. If you are not creating an
          Event, please sign-in again once an Event is running.
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default CreateEventNotice;
