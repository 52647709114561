import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const VisitorIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '268'}
      height={height || '196'}
      overflow="hidden"
      fill={fill || 'black'}
      viewBox="0 0 220 196"
    >
      <g fill="none" stroke={fill || 'black'} strokeMiterlimit="8" strokeWidth="8.88">
        <path
          d="M1156.29 1001.5h89.21v154h-204v-154h88.92"
          transform="translate(-1037 -974)"
        ></path>
        <path
          d="M1156.84 1021.5h65.66v113h-158v-113h64.72"
          transform="translate(-1037 -974)"
        ></path>
        <path
          d="M1129.5 978.5h28v55h-28zM1119.5 1066c0 8.01-6.49 14.5-14.5 14.5s-14.5-6.49-14.5-14.5 6.49-14.5 14.5-14.5 14.5 6.49 14.5 14.5zM1105.01 1088.5c-15.75 0-28.51 13.43-28.51 30h57c0-16.57-12.75-30-28.49-30zM1153.5 1059.5h53M1153.5 1085.5h53M1153.5 1110.5h53"
          transform="translate(-1037 -974)"
        ></path>
      </g>
    </svg>
  )
}

VisitorIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
