import React, { Component } from "react";

import PeepListItem from "./PeepListItem";
import {
  api_floor_evacuation_peep_search,
  api_floor_evacuation_peep_data
} from "../../../../config";

import axios from "axios";

import {
  ArrowChevronIcon,
  ExclamationIcon,
  SearchIcon
} from "../../../Icons";

class PeepList extends Component {
  state = {
    show: true,
    search: ""
  };

  componentDidMount() {
    if (!this.props.peep.length) {
      this.setState({
        show: false
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.peep && this.props.peep) {
      if (prevProps.peep.length !== this.props.peep.length) {
        if (!this.props.peep.length && this.state.search.length === 0) {
          this.setState({
            show: false
          });
        } else {
          this.setState({
            show: true
          });
        }
      }
    }

    if (prevState.search.length !== this.state.search.length) {
      if (this.state.search.length === 0) {
        this.loadSelectedFloorPeep();
      }
    }
  }

  toggleCollapse = () => {
    this.setState({
      ...this.state,
      show: !this.state.show
    });
  };

  loadSelectedFloorPeep = () => {
    let searchUrl =
      api_floor_evacuation_peep_data + "/" + this.props.selectedFloor;
    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setPeepsList(resp.data);
      })
      .catch(err => {
      });
  };

  searchEvacuees = () => {
    let searchUrl = api_floor_evacuation_peep_search + "/" + this.state.search;
    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setPeepsList(resp.data);
      })
      .catch(err => {
      });
  };

  handleSearchChange = e => {
    this.setState(
      {
        ...this.state,
        search: e.target.value
      },
      () => {
        if (this.state.search.length >= 1) {
          this.searchEvacuees();
        }
      }
    );
  };

  spinRefresh = () => {
    this.setState({
      ...this.state,
      spinRefresh: !this.state.spinRefresh
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        spinRefresh: !this.state.spinRefresh
      });
    }, 1000);
  };

  collapsePeep = () => {
    let collapseContent = "collapse-content";
    if (!this.state.show) {
      collapseContent += " hide";
    }

    let errorMsg =
      this.state.search.length >= 3
        ? "No PEEPs Found..."
        : "No PEEPs on this floor";
    return (
      <>
        <div className="container">
          <div className="collapse-disability">
            <div className="col-row" onClick={this.toggleCollapse}>
              <div className="col-left">
                <h3>People with Disability on this Floor</h3>
              </div>
              <div className="col-right">
                {this.props.peep && this.props.peep.length > 0 ?
                  <span className="counter">{this.props.peep.length}</span>
                : null}
                <span className="arrow">
                  {this.state.show ?
                    <ArrowChevronIcon width={14} height={14} direction="up" />
                    :
                    <ArrowChevronIcon width={14} height={14} />
                  }
                </span>
              </div>
            </div>
            <div className={collapseContent}>
              <div className="col-search">
                <div className="search-row">
                  <div className="search-left">
                    <span className="search-icon">
                      <SearchIcon width={17} height={17} fill="#aaa" />
                    </span>
                    <input
                      type="text"
                      className="browser-default search-input"
                      placeholder="Search PEEPS"
                      value={this.state.search}
                      onChange={this.handleSearchChange}
                    />
                  </div>
                  <div className="search-right">
                    <button
                      className={this.state.spinRefresh ? 'active btn-refresh' : 'btn-refresh'}
                      onClick={
                        () => {
                          this.spinRefresh();
                          this.searchEvacuees();
                      }}
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" /></svg>
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-peeps">
                {this.props.peep && this.props.peep.length > 0 ? (
                  <React.Fragment>
                    {this.props.peep.map(evacuee => {
                      return (
                        <PeepListItem
                          key={evacuee.id + evacuee.name + " peep"}
                          info={evacuee}
                          pwdmi_statuses={this.props.pwdmi_statuses}
                          input={this.props.input}
                          selectedFloor={this.props.selectedFloor}
                          handleStatus={this.props.handleStatus}
                          refreshSearchEvacuees={this.searchEvacuees}
                        />
                      );
                    })}
                  </React.Fragment>
                ) : (
                  <div className="not-found">
                    <p className="not-found-title">{errorMsg}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  alertWarning() {
    return (
      <div className="alert alert--floorevac">
        <div className="container">
          <div className="alert-row">
            <div className="alert-icon">
              <ExclamationIcon height={30} fill={'#333'} />
            </div>
            <div className="alert-content">
              Only record the safety status of persons who will remain on this floor.
            </div>
          </div>
          <span className="alert-bgoverlay" />
        </div>
      </div>
    )
  }

  /*
  renderSkeleton = () => {
    return (
      <div className="container mt-20">
        <div className="skeleton skeleton__table" />
      </div>
    );
  };
  */

  render() {
    /*
    if (!this.props.loading && !this.props.onlyPeepLoad) {
      return (
        <React.Fragment>
          {this.alertWarning()}
          {this.collapsePeep()}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
    */
    return (
      <React.Fragment>
        {this.alertWarning()}
        {this.collapsePeep()}
      </React.Fragment>
    );
  }
}

export default PeepList;
