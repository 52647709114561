import React, { Component } from "react";
import axios from "axios";
import { api_floor_evacuation_evacuee_search } from "../../../../config";
import OccupantListItem from "./OccupantListItem";
import { ArrowChevronIcon, SearchIcon } from "../../../Icons";

class Occupant extends Component {
  state = {
    search: "",
    searchPersons: [],
    show: true,
  };

  toggleCollapse = () => {
    this.setState({
      ...this.state,
      show: !this.state.show
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.search !== prevState.search &&
      this.state.search.length === 0
    ) {
      this.props.loadPaginatedEvacuees();
    }
  }

  searchEvacuees = () => {
    let searchUrl = api_floor_evacuation_evacuee_search + "/" + this.state.search;
    axios
      .get(searchUrl)
      .then(resp => {
        this.props.setEvacueesList(resp.data);
      })
      .catch(err => {
      });
  };

  handleSearchChange = e => {
    this.setState(
      {
        ...this.state,
        search: e.target.value
      },
      () => {
        if (this.state.search.length >= 1) {
          this.searchEvacuees();
        }
      }
    );
  };

  occupantsCounter = () => {
    return this.props.occupants.length;
  };

  spinRefresh = () => {
    this.setState({
      ...this.state,
      spinRefresh: !this.state.spinRefresh
    });
    setTimeout(() => {
      this.setState({
        ...this.state,
        spinRefresh: !this.state.spinRefresh
      });
    }, 1000);
  };

  renderOccupantItems = () => {
    if (this.props.occupants.length > 0) {
      return (
        this.props.occupants.map(occupant => {
          return (
            <OccupantListItem
              occupant={occupant}
              key={occupant.evacuee_list_uid}
              loading={this.props.loading}
              refusal_to_evacuate={this.props.refusal_to_evacuate}
              handleReportedChange={this.props.handleReportedChange}
              input={this.props.input}
              selectedFloor={this.props.selectedFloor}
              handleStatus={this.props.handleStatus}
              refreshSearchEvacuees={this.searchEvacuees}
            />
          )
        })
      )
    } else {
      return (
        <div className="not-found">
          <p className="not-found-title">No Occupant Found..</p>
        </div>
      )
    }
  };

  collapseOccupants = () => {
    let collapseContent = "collapse-content";
    if (!this.state.show) {
      collapseContent += " hide";
    }

    return (
      <div className="container">
        <div className="collapse-occupants">
          <div className="col-row" onClick={this.toggleCollapse}>
            <div className="col-left">
              <h3>All Occupants</h3>
            </div>
            <div className="col-right">
              <span className="counter hidden">{this.occupantsCounter()}</span>
              <span className="arrow">
                {this.state.show ?
                  <ArrowChevronIcon width={14} height={14} direction="up" />
                  :
                  <ArrowChevronIcon width={14} height={14} />
                }
              </span>
            </div>
          </div>
          <div className={collapseContent}>
            <div className="col-search">
              <div className="search-row">
                <div className="search-left">
                  <span className="search-icon">
                    <SearchIcon width={17} height={17} fill="#aaa" />
                  </span>
                  <input
                    type="text"
                    className="browser-default search-input"
                    placeholder="Search Occupants"
                    value={this.state.search}
                    onChange={this.handleSearchChange}
                  />
                </div>
                <div className="search-right">
                  <button
                    className={this.state.spinRefresh ? 'active btn-refresh' : 'btn-refresh'}
                    onClick={
                      () => {
                        this.spinRefresh();
                        this.searchEvacuees();
                      }}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H464c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0s-87.5 229.3 0 316.8s229.3 87.5 316.8 0c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0c-62.5 62.5-163.8 62.5-226.3 0s-62.5-163.8 0-226.3s163.8-62.5 226.3 0L386.3 160z" /></svg>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-occupants">
              {this.renderOccupantItems()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  /*
  renderSkeleton = () => {
    return (
      <div className="container mt-20">
        <div className="skeleton skeleton__table" />
      </div>
    );
  };
  */

  render() {
    /*
    if (!this.props.loading) {
      return (
        <React.Fragment>
          {this.collapseOccupants()}
        </React.Fragment>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
    */
    return <React.Fragment>{this.collapseOccupants()}</React.Fragment>
  }
}

export default Occupant;
