import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const AreasIcon = ({ width, height, fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width || '99'}
      height={height || '110'}
      overflow="hidden"
      fill={fill || 'black'}
      viewBox="0 0 99 110"
    >
      <g transform="translate(-634 -211)">
        <path d="M671,228h9v10h-9V228z" />
        <path d="M650,228h10v10h-10V228z" />
        <path d="M707,262h9v10h-9V262z" />
        <path d="M686,262h10v10h-10V262z" />
        <path d="M666,262h10v10h-10V262z" />
        <path d="M707,282h9v10h-9V282z" />
        <path d="M686,282h10v10h-10V282z" />
        <path d="M666,282h10v10h-10V282z" />
        <path d="M707,302h9v10h-9V302z" />
        <path d="M666,302h10v10h-10V302z" />
        <path d="M687,302h10v10h-10V302z" />
        <path d="M718.1,244.6h-20.8V226c0-8.3-6.7-15-14.9-15h-33.5c-8.2,0-14.9,6.7-14.9,15v95h7.2v-95c0-4.3,3.5-7.8,7.7-7.8
		h33.5c4.3,0,7.7,3.5,7.7,7.8v18.6h-25.3c-8.2,0-14.9,6.7-14.9,15V321h7.2v-61.4c0-4.3,3.5-7.8,7.7-7.8h53.3c4.3,0,7.7,3.5,7.7,7.8
		V321h7.2v-61.4C733,251.4,726.3,244.6,718.1,244.6z"/>
      </g>
    </svg>
  )
}

AreasIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
