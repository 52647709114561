import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../../Routes";
import { MessageIcon, PeepIcon, PhoneIcon } from "../../../Icons";
import refusal_black from "../images/refusal_red.png";

// This should be same with API requirement (can't use danger, safe like evacuee safety)
// Should be match with this values: this.props.pwdmi_statuses
export const STATUSES = {
  SAFE: "safe",
  SAFE_1: "Safe",
  SAFE_2: "safe on floor",
  SAFE_3: "Safe On Floor",
  UNKNOWN: "unknown",
  UNKNOWN_1: "Unknown",
  DANGER: "danger", //not allowed on the /api/floor/evacuation/updatePeepStatus
  DANGER_1: "In Danger",
};
class OccupantListItem extends Component {
  state = {
    showStatusModal: false
  };

  toggleStatusModal = () => {
    this.setState({
      ...this.state,
      showStatusModal: !this.state.showStatusModal
    });
  };

  statusVariants = () => {
    let statusText = '';
    let statusTextOn = '';
    let assist = this.props.occupant.special[0];
    let refusal = this.props.occupant.special[2];
    let status = this.props.occupant.status;

    switch (status) {
      case STATUSES.SAFE:
        statusText = 'Safe';
        statusTextOn = 'Safe on';
        break;
      case STATUSES.SAFE_1:
        statusText = 'Safe';
        statusTextOn = 'Safe on';
        break;
      case STATUSES.SAFE_2:
        statusText = 'Safe';
        statusTextOn = 'Safe on';
        break;
      case STATUSES.SAFE_3:
        statusText = 'Safe';
        statusTextOn = 'Safe on';
        break;
      case STATUSES.DANGER:
        statusText = 'Danger';
        statusTextOn = 'Danger on';
        break;
      case STATUSES.DANGER_1:
        statusText = 'Danger';
        statusTextOn = 'Danger on';
        break;
      case STATUSES.UNKNOWN_1:
        statusText = 'Unknown'
        statusTextOn = 'Unknown on'
        break;
      default:
        statusText = 'Unknown'
        statusTextOn = 'Unknown on'
        break;
    }

    if (assist.name === 'assist' && assist.detail) {
      return (
        <>
          <span className="status-text">
            {statusTextOn}
          </span>
          <span className="status-location">
            {assist.detail ? assist.detail : null}
          </span>
        </>
      )
    } else if (refusal.name === 'refusal' && refusal.state && refusal.detail) {
      return (
        <>
          <span className="status-text">
            {statusTextOn}
          </span>
          <span className="status-location">
            {refusal.detail ? refusal.detail : null}
          </span>
        </>
      )
    } else {
      return <span className="status-text">{statusText}</span>
    }
  }

  additionalInfo = () => {
    return this.props.occupant.special.map((special, idx) => {

      //IF Assist
      if (special.name === 'assist' && special.state) {
        return (
          <span key={idx} className="info-peep">
            <PeepIcon width={20} height={20} fill={'white'} />
          </span>
        )
      }

      //IF Refusal
      if (special.name === 'refusal' && special.state) {
        return (
          <span key={idx} className="tooltipped special__icon special__icon--refusal hidden" title="refusal" />
        )
      }

      //DEFAULT (IF NOT ALL OF THEM)
      return null
    })
  }

  refusalAction = () => {
    let classStatus = "";
    if (this.props.occupant.reported) {
      classStatus = "active";
    } else {
      classStatus = "inactive";
    }

    return (
      <div className="card__occupant-refusal">
        <button
          className={`refusal ${classStatus}`}
          onClick={() => {
            this.props.handleReportedChange(this.props.occupant.id);
            setTimeout(() => {
              this.props.refreshSearchEvacuees();
            }, 500);
          }}
        >
          <img src={refusal_black} alt="refusal img" />
        </button>
      </div>
    )
  }

  messageVariants = () => {
    if (this.props.occupant.message !== null) {
      let baseClass = "special__icon special__icon--";
      let noReplyClass = null;
      let unreadClass = null;
      if (this.props.occupant.message.noreply) {
        noReplyClass = baseClass + "noreply";
      }
      if (this.props.occupant.message.unread) {
        unreadClass = baseClass + "unread";
      }
      return (
        <React.Fragment>
          {unreadClass !== null ? <span className={unreadClass} /> : null}
          {noReplyClass !== null ? <span className={noReplyClass} /> : null}
        </React.Fragment>
      );
    }
    return null;
  };

  render() {
    let dangerDisabled = this.props.occupant.status === STATUSES.DANGER ? true : false;
    let safeDisabled = this.props.occupant.status === STATUSES.SAFE ? true : false;
    let unknownDisabled = this.props.occupant.status === STATUSES.UNKNOWN ? true : false;
    let statusClassName = this.props.occupant.status.replace(/\s+/g, '').toLowerCase();

    return (
      <React.Fragment>
        <div className="card__occupant">
          <div className="card__occupant-left">
            <div className="card__occupant-name">
              {this.props.occupant.name}
            </div>
            <div className="card__occupant-current-location">
              {this.props.occupant.location}
            </div>
            <div className="card__occupant-additional">
              <div className="card__occupant-status">
                <button
                  className={`status status--${statusClassName}`}
                  onClick={() => {
                    this.toggleStatusModal();
                  }}
                >
                  {this.statusVariants()}
                </button>
                {this.state.showStatusModal &&
                  <>
                    <div className="status-modal">
                      {!safeDisabled ? (
                        <button
                          className="status status--safe"
                          data-status={this.props.occupant.status}
                          onClick={
                            () => {
                              this.props.handleStatus(
                                this.props.occupant.id,
                                STATUSES.SAFE,
                                this.props.input.floor
                              );
                              this.toggleStatusModal();
                              setTimeout(() => {
                                this.props.refreshSearchEvacuees();
                              }, 1000);
                            }}
                        >
                          <span className="status-text">
                            Safe on floor
                          </span>
                        </button>
                      ) : null}
                      {!unknownDisabled ? (
                        <button
                          className="status status--unknown"
                          data-status={this.props.occupant.status}
                          onClick={
                            () => {
                              this.props.handleStatus(
                                this.props.occupant.id,
                                STATUSES.UNKNOWN,
                                this.props.input.floor
                              );
                              this.toggleStatusModal();
                              setTimeout(() => {
                                this.props.refreshSearchEvacuees();
                              }, 1000);
                            }}
                        >
                          <span className="status-text">
                            Unknown
                          </span>
                        </button>
                      ) : null}
                      {!dangerDisabled ? (
                        <button
                          className="status status--danger"
                          data-status={this.props.occupant.status}
                          onClick={
                            () => {
                              this.props.handleStatus(
                                this.props.occupant.id,
                                STATUSES.DANGER_1,
                                this.props.input.floor
                              );
                              this.toggleStatusModal();
                              setTimeout(() => {
                                this.props.refreshSearchEvacuees();
                              }, 1000);
                            }}
                        >
                          <span className="status-text">
                            Danger on floor
                          </span>
                        </button>
                      ) : null}
                    </div>
                    <div
                      className="status-modal-overlay"
                      onClick={() => {
                        this.toggleStatusModal();
                      }}
                    />
                  </>
                }
              </div>
              {this.additionalInfo()}
            </div>
          </div>
          <div className="card__occupant-right">
            <div className="indicators">
              <div className="message-icon">
                {this.messageVariants()}
              </div>
              {/* <button className="btn-peep">Peep</button> */}
            </div>
            <div className="cta">
              {this.refusalAction()}
              <div className="btn-message">
                <Link
                  to={
                    route.message.evacuee.link +
                    "/" +
                    this.props.occupant.evacuee_list_uid +
                    "?from=" +
                    route.floor.evacuation.link
                  }
                >
                  <MessageIcon width="25px" height="25px" fill="white" />
                </Link>
              </div>
              <div className="btn-phone">
                <a href={`tel:${this.props.occupant.phone}`}>
                  <PhoneIcon width="25px" height="25px" fill="white" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default OccupantListItem;
