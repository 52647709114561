import React, { Component } from "react";

import MessageTemplateItem from "./MessageTemplateItem";
import "./scss/messagetemplate.scss";

class MessageTemplate extends Component {
  renderTemplateItems = () => {
    return (
      this.props.templates.length > 0 ?
      this.props.templates.sort((a, b) => b.id - a.id).map(template => {
        return (
          <MessageTemplateItem
            key={template.id + " message template"}
            template={template}
            insertTemplate={this.props.insertTemplate}
          />
        );
      }) : <div className="col s12 messagebox__templateItem center-text">
        No Templates Available
      </div>
    );
  };

  render() {
    return (
      <div className="col s12 messagebox__templates">
        {this.renderTemplateItems()}
      </div>
    );
  }
}

export default MessageTemplate;
