import React, { Component } from "react";
import { Link } from "react-router-dom";
import { route } from "../../Routes";
import { ArrowChevronIcon, MessageIcon, PhoneIcon } from "../../Icons";

class FloorWarden extends Component {
  state = {
    floorOpenId: 0,
  };

  toggleFloorOpen = e => {
    this.setState({
      ...this.state,
      floorOpenId: e === this.state.floorOpenId ? 0 : e
    });
  };

  beLowercase = (e) => {
    return e.replace(/\s+/g, '').toLowerCase();
  }

  floorWarden = () => {
    return (
      this.props.wardens && this.props.wardens.length > 0 ?
        <div className="floorwarden__lists">
          {this.props.wardens.map((floor, floor_idx) => (
              <div className="item" key={floor_idx}>
                <div className="item-row">
                  <div className="item-left">
                    <div className="item-place">
                      {floor.wardens.length ?
                        <button onClick={() => this.toggleFloorOpen(floor.id)}>
                          <span>{floor.name}</span>
                          <span className="item-arrow">
                          {this.state.floorOpenId === floor.id ?
                            <ArrowChevronIcon direction="up" width={14} height={14} />
                            :
                            <ArrowChevronIcon width={14} height={14} />
                          }
                          </span>
                        </button>
                      :
                        <span className="item-place-empty-warden">{floor.name}</span>
                      }
                    </div>

                  </div>
                  <div className="item-right">
                    <div className={`item-status color--${this.beLowercase(floor.colour)}`}>
                      <span className="item-status-box cut-off">
                        {floor.status}
                      </span>
                    </div>
                    <div className="item-warden">
                      <span className="item-warden-counter on-duty">
                        {floor.wardens_on_duty_count}
                      </span>
                      <span className="item-warden-counter unknown">
                        {floor.wardens_unknown_count}
                      </span>
                      <span className="item-warden-counter not-avail">
                        {floor.wardens_not_available_count}
                      </span>
                    </div>
                  </div>
                </div>
                {this.state.floorOpenId === floor.id && (
                    <div className="item-detail">
                      <h4 className="item-detail-caption">
                        Floor / Area Wardens
                      </h4>
                      <div className="item-detail-box">
                      {floor.wardens.map((warden, warden_idx) => (
                        <div className="card__floorwarden" key={warden_idx}>
                          <div className="card__floorwarden-left">
                            <div className={`card__floorwarden-bar color--${this.beLowercase(warden.status)}`}/>
                            <div className="card__floorwarden-name">
                              {warden.name}
                            </div>
                          </div>
                          <div className="card__floorwarden-right">
                            <div className="cta">
                              <div className="btn-message">
                                <Link
                                  to={
                                    route.message.warden.link +
                                    "/" +
                                    warden.id +
                                    "?from=" +
                                    route.floor.evacstatus.link
                                  }
                                >
                                  <MessageIcon width="25px" height="25px" fill="white" />
                                </Link>
                              </div>
                              <div className="btn-phone">
                                <a href={`tel:${warden.phone}`}>
                                  <PhoneIcon width="25px" height="25px" fill="white" />
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                        <div className="actions on-right">
                          {floor.wardens.length > 0 &&
                            <div className="btn-broadcast">
                              <Link
                                to={{
                                  pathname: route.message.group.link,
                                  from: route.floor.evacstatus.link,
                                  state: {
                                    audience: floor.wardens,
                                    sms_allowed: true
                                  }
                                }}
                              >
                                <span className="btn-broadcast-text">
                                  Broadcast
                                </span>
                                <span className="btn-broadcast-icon">
                                  <MessageIcon width="25px" height="25px" fill="white" />
                                </span>
                              </Link>
                            </div>
                          }
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            ))
          }
        </div>
      :
        <div className="container">
          <div className="not-found">
            <p className="not-found-title">No data found</p>
          </div>
        </div>
    )
  }

  render() {
    return (
      <>
        <div className="floorwarden__list-head">
          <div className="container">
            <div className="floorwarden__list-head-row">
              <div className="floorwarden__list-head-left">
                Floor / Area
              </div>
              <div className="floorwarden__list-head-right">
                <div className="floorwarden__list-head-right-status">
                  Status
                </div>
                <div className="floorwarden__list-head-right-warden">
                  Wardens
                </div>
              </div>
            </div>
            <div className="floorwarden__list-head-overlay" />
          </div>
        </div>
        <div className="container">
          {this.floorWarden()}
        </div>
      </>
    );
  }
}

export default FloorWarden;
