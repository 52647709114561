import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const ShowAllIcon = ({ width, height, fill }) => {
  return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={width || '306'}
			height={height || '308'}
			overflow="hidden"
			viewBox="0 0 306 308"
			fill={fill || 'black'}
		>
			<path
				stroke={fill || 'black'}
				fillRule="evenodd"
				d="M2772.05 1978.26c11.4 4.76 24.52 2.14 33.25-6.64l31.78-31.93h170.34c28.07 0 50.82-22.92 50.82-51.17v-163.75c0-28.25-22.75-51.16-50.82-51.16h-203.35c-28.07 0-50.83 22.91-50.83 51.16v225.15c0 12.4 7.42 23.6 18.81 28.34zm11.69-69.27v-184.22c0-11.3 9.11-20.46 20.33-20.46h203.35c11.22 0 20.32 9.16 20.32 20.46v163.75c0 11.3-9.1 20.47-20.32 20.47h-183l-40.68 40.93z"
				transform="translate(-2753 -1673)"
			></path>
			<path
				fill="none"
				stroke={fill || 'black'}
				strokeLinecap="round"
				strokeMiterlimit="8"
				strokeWidth="1.146"
				d="M2843.5 1757.5h142.91M2844.5 1804.5h142.91M2846.5 1852.5h142.91"
				transform="translate(-2753 -1673)"
			></path>
    </svg>
  )
}

ShowAllIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
