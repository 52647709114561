import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const SmsIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '16'}
      height={height || '16'}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill={fill || 'black'} d="M13.85,2.33h-9.9v1h9.4v6.25h1V2.83C14.35,2.55,14.13,2.33,13.85,2.33z" />
      <path fill={fill || 'black'} d="M9.69,8.72c0.4,0,0.72-0.32,0.72-0.72c0-0.4-0.32-0.72-0.72-0.72S8.96,7.6,8.96,8C8.96,8.4,9.29,8.72,9.69,8.72z" />
      <path fill={fill || 'black'} d="M6.99,8.72c0.4,0,0.72-0.32,0.72-0.72c0-0.4-0.32-0.72-0.72-0.72C6.59,7.28,6.26,7.6,6.26,8C6.26,8.4,6.59,8.72,6.99,8.72z" />
      <path fill={fill || 'black'} d="M4.51,7.28C4.11,7.28,3.79,7.6,3.79,8c0,0.4,0.32,0.72,0.72,0.72c0.4,0,0.73-0.32,0.73-0.72C5.24,7.6,4.91,7.28,4.51,7.28z" />
      <path fill={fill || 'black'} d="M2.15,11.88H5.5l1.2,1.59c0.09,0.13,0.24,0.2,0.4,0.2c0.16,0,0.31-0.07,0.4-0.2l1.2-1.59h3.35c0.28,0,0.5-0.23,0.5-0.5V4.62  c0-0.27-0.22-0.5-0.5-0.5h-9.9c-0.28,0-0.5,0.23-0.5,0.5v6.76C1.65,11.65,1.87,11.88,2.15,11.88z M2.65,5.12h8.9v5.76h-3.1  c-0.16,0-0.31,0.07-0.4,0.2L7.1,12.34l-0.95-1.26c-0.09-0.13-0.24-0.2-0.4-0.2h-3.1V5.12z" />
    </svg>
  )
}

SmsIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
