import React, { Component } from "react";
import { ArrowChevronIcon } from "../../Icons";

class MessageFilterSelector extends Component {
  state = {
    expand: false
  };

  isChecked = value => {
    if (this.state.selected.includes(value)) {
      return true;
    }
    return false;
  };

  toggleExpand = () => {
    this.setState(
      {
        ...this.state,
        expand: !this.state.expand
      },
      () => {
        if (!this.state.expand && this.props.onClose !== undefined) {
          this.props.toggleTab(this.props.label);
          this.props.onClose();
        } else {
          this.props.toggleTab(this.props.label);
        }
      }
    );
  };

  renderSelectionList = () => {
    return (
      this.state.expand &&
      this.props.selectorContent.map(content => {
        let id = content.id !== undefined ? content.id : content.name;
        return (
          <div
            key={content.id + content.name + "__" + this.props.label}
            className="checkbox-child"
          >
            <label>
              <input
                type="checkbox"
                className="filled-in"
                value={id}
                onChange={
                  () => {
                    this.props.update(id)
                    setTimeout(() => {
                      this.props.onClose()
                    }, 1000);
                  }
                }
                checked={
                  this.props.selected.includes("all") ||
                  this.props.selected.includes(id.toString())
                }
              />
              <span className="checkbox-text">
                {content.name}
              </span>
            </label>
          </div>
        );
      })
    );
  };

  renderSelectedText = () => {
    if (this.props.selected.includes("all")) {
      return "All";
    } else if (this.props.selected.length > 0) {
      return this.props.selected.length + " selected";
    } else {
      return "All";
    }
  };

  render() {
    return (
      <div className="col-right">
        <button className="box-checkboxes" onClick={this.toggleExpand}>
          <span className="box-checkboxes-text">
            {this.renderSelectedText()}
          </span>
          <span className="box-checkboxes-icon">
            {this.state.expand ?
              <ArrowChevronIcon direction="up" width={14} height={14} />
            :
              <ArrowChevronIcon width={14} height={14} />
            }
          </span>
        </button>
        {this.state.expand && (
          <div className="box-checkboxes-accordion">
            <div className="checkbox-parent">
              <label>
                <input
                  type="checkbox"
                  className="filled-in"
                  value="all"
                  onChange={e => {
                    this.props.update(e.target.value)
                    setTimeout(() => {
                      this.props.onClose()
                    }, 1000);
                  }}
                  checked={this.props.selected.includes("all")}
                />
                <span className="checkbox-text">all</span>
              </label>
            </div>
            {this.renderSelectionList()}
          </div>
        )}
      </div>
    );
  }
}

export default MessageFilterSelector;
