import React from "react";

import "./scss/loader.scss";

const Loader = props => {
  let showLoader = () => {
    if (props.loading) {
      return (
        <div className="loading-overlay">
          <div className="spinner" />
        </div>
      );
    }
  };

  return <React.Fragment>{showLoader()}</React.Fragment>;
};

export default Loader;
