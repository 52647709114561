import React, { Component } from "react";

class BuildingSelect extends Component {
  renderBuildings = () => {
    return this.props.no_event ? (
      <option className="floor__status--option">No Current Events</option>
    ) : (
      this.props.buildings.map(bldg => {
        return (
          <option
            key={bldg.id + " " + bldg.name + " selectoption"}
            value={bldg.id}
            className="floor__status--option"
          >
            {bldg.name}
          </option>
        );
      })
    );
  };

  handleBuildingChange = e => {
    this.props.handleBuildingSelectChange(parseInt(e.target.value, 10));
  };

  getSelectedValue = () => {
    return this.props.buildings
      .filter(bldg => {
        return bldg.selected === true;
      })
      .map(bldg => {
        return bldg.id.toString(10);
      })[0];
  };

  renderIfNotLoading = () => {
    if (!this.props.loading) {
      return (
        <select
          className="browser-default"
          onChange={this.handleBuildingChange}
          value={this.getSelectedValue()}
        >
          {this.renderBuildings()}
        </select>
      );
    } else {
      return <div className="skeleton skeleton__field br-6" />;
    }
  };

  render() {
    return (
      <div className="floorwarden-head">
        <div className="container">
          <div className="floorwarden__building">
            <div className="floorwarden__building-label">
              Building
            </div>
            <div className="floorwarden__building-select">
              {this.renderIfNotLoading()}
            </div>
          </div>

          <div className="floorwarden__displaybar">
            <div className="act--onduty act on--active">
              <div className="label">On Duty</div>
              <div className="counter">
              {this.props.amount && this.props.amount.on_duty ?
                this.props.amount.on_duty : 0
              }
              </div>
            </div>
            <div className="act--unknown act on--active">
              <div className="label">Unknown</div>
              <div className="counter">
                {this.props.amount && this.props.amount.unknown ?
                  this.props.amount.unknown : 0
                }
              </div>
            </div>
            <div className="act--notavail act on--active">
              <div className="label">Not Avail.</div>
              <div className="counter">
                {this.props.amount && this.props.amount.off_duty && this.props.amount.not_available  ?
                  this.props.amount.off_duty + this.props.amount.not_available
                  : 0
                }
              </div>
            </div>
            <div className="act--offduty act on--active">
              <div className="label">Off Duty</div>
              <div className="counter">
                {this.props.amount && this.props.amount.off_duty ?
                  this.props.amount.off_duty : 0
                }
              </div>
            </div>
            <div className="act--total act on--active">
              <div className="label">Total</div>
              <div className="counter">
                {this.props.amount && this.props.amount.total ?
                  this.props.amount.total : 0
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default BuildingSelect;
