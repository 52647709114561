import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const PhoneIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '73'}
      height={height || '90'}
      viewBox="0 0 75 90"
      fill={fill || 'black'}
      xmlns="http://www.w3.org/2000/svg"
      overflow="hidden"
    >
      <path d="M42.6,56.25c-8.36-11.93-11.42-18.74-3.72-24.13,6.62-4.63,6.18-5.15.22-16.65C33.6,4.84,31.31,2.09,23.74,8.19,6.9,19.9,11.38,38.89,29.7,65.05S65,101.92,81.31,90.41c8.33-5,6.53-8.12-1.58-16.92C71,64,70.54,63.49,64,68.05c-7.58,5.31-13.07.14-21.43-11.8Z" transform="translate(-13.25 -5)" />
    </svg>
  )
}

PhoneIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}