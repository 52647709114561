import React, { Component } from "react";
import "./scss/toast.scss";

/**
 * Prop List
 * 
 * TOAST_TYPE toastType
 * Boolean show
 * String children
 * Function close
 */

export const DELAY = 7000; // milliseconds (ms) 1000ms = 1second

export const TOAST_TYPE = {
  DANGER: "ns__toast--danger",
  WARNING: "ns__toast--warning",
  SUCCESS: "ns__toast--success"
};

export class Toast extends Component {
  componentDidMount() {
    //close after 5 seconds
    
    if(this.props.show)
    {
      this.closeByTimeout();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show) {
      this.closeByTimeout();
    }
  }

  closeByTimeout = () => {

    let delay = DELAY;
    if(this.props.delay !== undefined && this.props.delay >= 1000) {
      delay = this.props.delay;
    }
    
    setTimeout(this.props.close, delay);
  }

  render() {
    let className = "ns__toast ";
    if (this.props.toastType !== undefined) {
      className = className + this.props.toastType;
    } else {
      className = className + TOAST_TYPE.DANGER;
    }
    return this.props.show ? (
      <div className={className}>{this.props.children}</div>
    ) : null;
  }
}

export default Toast;
