import React from "react";

const Header = props => {
  return (
    <div className="header black">
      <div className="brand-logo2x" />
    </div>
  );
};

export default Header;
