import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const WarningIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 100 125"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M50 11.7c3.7 0 7.2 2.5 10.3 7.5l28.9 52.9.1.2.1.2c1.9 2.7 2.6 4.7 2.6 6.9 0 5.1-4.3 8.9-9.9 8.9H18c-5.7 0-9.9-3.8-9.9-8.9 0-2.2.8-4.2 2.6-6.9l.1-.2.1-.2 28.9-52.9c3-5 6.5-7.5 10.2-7.5m0-4c-5.2 0-9.9 3.2-13.7 9.5l-28.9 53c-2.2 3.2-3.3 6-3.3 9.2 0 7.4 6.3 12.9 13.9 12.9h64c7.7 0 13.9-5.4 13.9-12.9 0-3.2-1.2-6-3.3-9.2l-28.9-53c-3.8-6.3-8.5-9.5-13.7-9.5z"
      fill={fill || 'black'}
      ></path>
      <path d="M45.4 32.8c-.1-1.3.4-2.5 1.3-3.5 1.8-1.8 4.8-1.8 6.6 0 1 1 1.5 2.4 1.3 3.8v.3l-3 28.1c-.1.9-.8 1.5-1.6 1.5-.7 0-1.4-.8-1.5-1.5l-3.2-28.1c0-.2 0-.4.1-.6z"
      fill={fill || 'black'}
      ></path>
      <circle cx="50" cy="71.4" r="4.7" fill={fill || 'black'}></circle>
    </svg>
  )
}

WarningIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}

export const Warning2Icon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M22.8786 18.3985L14.1412 3.22536C13.9281 2.85242 13.6085 2.54342 13.2355 2.33031C12.8626 2.10655 12.4364 2 11.9995 2C11.5626 2 11.1364 2.10655 10.7635 2.33031C10.3906 2.54342 10.0709 2.85242 9.85779 3.22536L1.12044 18.3985C0.907334 18.7608 0.800781 19.1657 0.800781 19.5919C0.800781 20.0075 0.907334 20.4124 1.12044 20.7746C1.33355 21.1582 1.65321 21.4672 2.02614 21.6803C2.39908 21.8934 2.81463 22 3.26216 22H20.7582C21.1844 22 21.5999 21.8934 21.9729 21.6803C22.3458 21.4672 22.6655 21.1476 22.8786 20.7746C23.0917 20.423 23.1982 20.0075 23.1982 19.5919C23.1982 19.1657 23.0917 18.7608 22.8786 18.3985ZM20.7369 20.3804H3.2515C3.10233 20.3804 2.96381 20.3378 2.83594 20.2738C2.70808 20.1993 2.60153 20.0927 2.52694 19.9648C2.46301 19.8476 2.42039 19.7198 2.42039 19.5812C2.42039 19.4534 2.46301 19.3255 2.52694 19.2083L11.2643 4.03516C11.3389 3.91795 11.4454 3.8114 11.5733 3.73681C11.7012 3.66223 11.8503 3.61961 11.9995 3.61961C12.1487 3.61961 12.2979 3.66223 12.4257 3.73681C12.5536 3.8114 12.6601 3.91795 12.7347 4.03516L21.4721 19.2083C21.536 19.3255 21.5786 19.4534 21.5786 19.5812C21.5786 19.7198 21.536 19.8476 21.4721 19.9648C21.3975 20.0927 21.2909 20.1993 21.1631 20.2738C21.0352 20.3378 20.8967 20.3804 20.7369 20.3804Z" fill={fill || 'black'} />
      <path d="M13.1716 17.2213C13.1716 17.4569 13.0999 17.6823 12.977 17.877C12.8438 18.0614 12.6593 18.2151 12.4544 18.3073C12.311 18.3586 12.1573 18.3893 12.0036 18.3893C11.9319 18.3893 11.8499 18.3893 11.7782 18.3688C11.5528 18.3278 11.3376 18.2151 11.1839 18.0512C11.02 17.8872 10.9073 17.6823 10.8561 17.4569C10.8151 17.2213 10.8356 16.9959 10.9278 16.7807C11.02 16.5655 11.1634 16.3811 11.3581 16.2581C11.5425 16.125 11.7679 16.0635 12.0036 16.0635C12.311 16.0635 12.6081 16.1864 12.8233 16.4016C13.0487 16.627 13.1716 16.9139 13.1716 17.2213Z" fill={fill || 'black'} />
      <path d="M12.782 8.38904V13.7681C12.782 13.9731 12.7 14.1677 12.5566 14.3214C12.4131 14.4649 12.2082 14.5468 12.0033 14.5468C11.7984 14.5468 11.5935 14.4649 11.45 14.3214C11.3066 14.178 11.2246 13.9731 11.2246 13.7681V8.38904C11.2246 8.18412 11.3066 7.9792 11.45 7.83576C11.5935 7.69232 11.7984 7.61035 12.0033 7.61035C12.2082 7.61035 12.4131 7.69232 12.5566 7.83576C12.7 7.9792 12.782 8.18412 12.782 8.38904Z" fill={fill || 'black'} />
    </svg>
  )
}

Warning2Icon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}