import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { route } from "../Routes";

import "../../scss/__colors.scss";

class ReportFormActions extends Component {
  state = {
    loading: true
  };

  handleNewEvent = () => {
    this.props.history.push(route.event.management.link);
  };

  renderNewEventButton = () => {
    return (
      <button
        onClick={this.handleNewEvent}
        disabled={this.props.loading}
        className="btn white-text waves-effect waves-light btn-large btn-flat red--bg report__actions--button"
      >
        <i className="material-icons report__actions--buttonIconTop">
          report_problem
        </i>
        <span className="report__actions--buttonText">Manage Event</span>
      </button>
    );
  };

  renderReportInButton = () => {
    return (
      <button
        onClick={this.props.handleReportIn}
        disabled={this.props.loading}
        className="btn white-text waves-effect waves-light btn-large btn-flat green--bg report__actions--button"
      >
        <i className="material-icons report__actions--buttonIconTop">done</i>
        <span className="report__actions--buttonText">Report In</span>
      </button>
    );
  };

  renderNotAvailableButton = () => {
    return (
      <button
        onClick={this.props.handleReportNa}
        disabled={this.props.loading}
        className="btn waves-effect waves-light btn-large btn-flat yellow--bg report__actions--button"
      >
        <i className="material-icons report__actions--buttonIconTop">close</i>
        <span className="report__actions--buttonText">Not Avail</span>
      </button>
    );
  };

  renderOffDutyButton = () => {
    return (
      <button
        onClick={this.props.handleLogout}
        disabled={
          this.props.loading
        }
        className="btn white-text waves-effect waves-light btn-large btn-flat orange darken-2 report__actions--button"
      >
        <i className="material-icons report__actions--buttonIconTop h-flip">
          exit_to_app
        </i>
        <span className="report__actions--buttonText">Sign Out</span>
      </button>
    );
  };

  render() {
    if (this.props.loading) {
      return null;
    } else {
      return (
        <div className="container report__actions">
          <div className="row">
            <div className="col s4">
              {this.props.active_event && !this.props.sign_in_data.on_duty
                ? this.renderReportInButton()
                : null}
            </div>
            <div className="col s4">
              {this.props.role !== null &&
              this.props.role.Module_Event_Management
                ? this.renderNewEventButton()
                : null}
            </div>
            <div className="col s4">
              {!this.props.sign_in_data.on_duty && this.props.active_event
              // !this.props.not_available
                ? this.renderNotAvailableButton()
                : null}
            </div>
          </div>
          <div className="row">
            <div className="col s4 offset-s4">
              {!this.props.reported_in && !this.props.active_event && !this.props.on_duty && !this.props.not_available
                ? this.renderOffDutyButton()
                : null}
            </div>
          </div>
        </div>
      );
    }
  }
}

export default withRouter(ReportFormActions);
