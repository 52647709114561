import React, { Component } from "react";

import ShowEvent from "./ShowEvent";
import EvacueeStatusSummary from "../../../Utilities/EvacueeStatusSummary";

class BuildingInfo extends Component {
  render() {
    var summary = {
      safe: this.props.building.wardens.on_duty,
      unknown: this.props.building.wardens.unknown,
      danger: this.props.building.wardens.not_available,
      total: this.props.building.wardens.off_duty
    };

    return (
      <React.Fragment>
        <ShowEvent
          loading={this.props.loading}
          event={this.props.building.event}
          no_event={this.props.no_event}
        />
        {this.props.loading ? (
          <div className="skeleton skeleton__field mt-30" />
        ) : (
          <EvacueeStatusSummary
            showBar={true}
            summary={summary}
            col1="on duty"
            col3="Not Avail"
            col4="off duty"
            hasTotal={false}
          />
        )}
      </React.Fragment>
    );
  }
}

export default BuildingInfo;
