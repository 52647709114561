import React from "react";

import "./scss/main.scss";

/**
 *
 * @param {string} props.summary.safe
 * @param {string} props.summary.unknown
 * @param {string} props.summary.danger
 * @param {string} props.summary.total
 */
const EvacueeStatusSummary = props => {
  const renderBars = () => {
    let safe =
      typeof props.summary.safe !== "number"
        ? parseInt(props.summary.safe, 10)
        : props.summary.safe;
    let danger =
      typeof props.summary.danger !== "number"
        ? parseInt(props.summary.danger, 10)
        : props.summary.danger;
    let unknown =
      typeof props.summary.unknown !== "number"
        ? parseInt(props.summary.unknown, 10)
        : props.summary.unknown;
    let custom =
      typeof props.summary.total !== "number"
        ? parseInt(props.summary.total, 10)
        : props.summary.total;
    let total = 0;
    if (props.hasTotal !== undefined && props.hasTotal === false) {
      total = safe + danger + unknown + custom;
    } else {
      total =
        typeof props.summary.total !== "number"
          ? parseInt(props.summary.total, 10)
          : props.summary.total;
    }

    let safeBar = { width: (safe / total) * 100 + "%" };
    let unknownBar = { width: (unknown / total) * 100 + "%" };
    let dangerBar = { width: (danger / total) * 100 + "%" };
    let safeClass = "aw__evacueeStatusSummary__summary--safe";
    let unknownClass = "aw__evacueeStatusSummary__summary--unknown";
    let dangerClass = "aw__evacueeStatusSummary__summary--danger";
    let customBar = 0+"%";
    let customClass = "aw__evacueeStatusSummary__summary--custom";

    if (props.hasTotal !== undefined && props.hasTotal === false) {
      customBar = { width: (custom / total) * 100 + "%" };
    }

    return (
      <div className="aw__evacueeStatusSummary__summary">
        <div style={safeBar} className={safeClass} />
        <div style={unknownBar} className={unknownClass} />
        <div style={dangerBar} className={dangerClass} />
        {props.hasTotal !== undefined && props.hasTotal === false ? (
          <div style={customBar} className={customClass} />
        ) : null}
      </div>
    );
  };

  const renderSummary = () => {
    return (
      <div className="row aw__evacueeStatusSummary">
        {/* <div className="col s3 aw__evacueeStatusSummary--summary">
          <div>Total</div>
          <div>
            {props.showBar !== undefined && props.showBar === true
              ? renderBars()
              : null}
          </div>
        </div> */}
        <div className="col s3 aw__evacueeStatusSummary--safe">
          <div>{props.col1 === undefined ? "Safe" : props.col1}</div>
          <div>
            {props.summary === undefined
              ? props.safe.toString()
              : props.summary.safe.toString()}
          </div>
        </div>
        <div className="col s3 aw__evacueeStatusSummary--unknown">
          <div>{props.col2 === undefined ? "Unknown" : props.col2}</div>
          <div>
            {props.summary === undefined
              ? props.unknown.toString()
              : props.summary.unknown.toString()}
          </div>
        </div>
        <div className="col s3 aw__evacueeStatusSummary--danger">
          <div>{props.col3 === undefined ? "Danger" : props.col3}</div>
          <div>
            {props.summary === undefined
              ? props.danger.toString()
              : props.summary.danger.toString()}
          </div>
        </div>
        <div className="col s3 aw__evacueeStatusSummary--total">
          <div>{props.col4 === undefined ? "Total" : props.col4}</div>
          <div>
            {props.summary === undefined
              ? props.total.toString()
              : props.summary.total.toString()}
          </div>
        </div>

        <div className="col s12 aw__evacueeStatusSummary--summary">
          <div className="container">
            {props.showBar !== undefined && props.showBar === true
              ? renderBars()
              : null}
          </div>
        </div>
        
      </div>
    );
  };

  return (
    <React.Fragment>
      {/* <div className="row floorevacstatus__label">
        <div className="col s12">Summary : </div>
      </div> */}
      {renderSummary()}
    </React.Fragment>
  );
};

export default EvacueeStatusSummary;
