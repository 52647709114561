import React, { Component } from "react";
import { ArrowChevronIcon } from "../../../Icons";

class Controls extends Component {
  state = {
    is_status: false
  }

  toggleStatus = () => {
    this.setState({
      is_status: !this.state.is_status,
    })
  }

  beLowercase = (e) => {
    if (e) {
      return e.replace(/\s+/g, '').toLowerCase();
    } else {
      return 'default';
    }
  }

  renderBuildingControl = () => {
    return (
      <div className="col-building">
        <div className="col-label">Building</div>
        <div className="col-select">
          <select
            className="browser-default"
            name="building"
            value={this.props.input.building}
            onChange={this.props.handleChange}
          >
            {this.props.buildings.map(bldg => {
              return (
                <option key={bldg.id + bldg.name} value={bldg.id}>
                  {bldg.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderFloorControl = () => {
    return (
      <div className="col-floor">
        <div className="col-label">Floor</div>
        <div className="col-select">
          <select
            className="browser-default"
            name="floor"
            value={this.props.input.floor}
            onChange={this.props.handleChange}
          >
            {this.props.floors.map(floor => {
              return (
                <option key={floor.id + floor.name} value={floor.id}>
                  {floor.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderStatusControl = () => {
    return (
      <div className="col-status hidden">
        <div className="col-label">Status</div>
        <div className="col-select">
          <select
            className="browser-default"
            name="status"
            value={this.props.input.status}
            onChange={this.props.handleChange}
            data-check-id={this.props.input.status}
          >
            {this.props.floor_status.map(status => {
              return (
                <option key={status.id + status.name} value={status.id}>
                  {status.name}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    );
  };

  renderStatusDropdown = () => {
    return (
      <div className="dropdown-status">
        <div className="col-status">
          <div className="col-label">
            <div className="dropdown-status-label">Status</div>
          </div>
          <div className="col-select">
            {
              this.props.status_selected &&
              this.props.status_selected.label &&
              this.props.status_selected.label.colour
              ?
                <button
                  className={`dropdown-status-current status--${this.beLowercase(this.props.status_selected.label.colour)}`}
                  data-check-id={this.props.status_selected.id}
                  onClick={() => {
                    this.toggleStatus();
                  }}
                >
                  {this.props.status_selected.name}
                  <span className="dropdown-status-arrowicon">
                    {this.state.is_status ?
                      <ArrowChevronIcon width={14} height={14} fill={'white'} direction="up" />
                      :
                      <ArrowChevronIcon width={14} height={14} fill={'white'} />
                    }
                  </span>
                </button>
              :
                <div className={`dropdown-status-current status--white`}></div>
            }

            {this.state.is_status &&
              <div className="dropdown-status-modal">
                <div className="status-modal">
                  {this.props.floor_status
                    .sort((a, b) => a.label.display_order - b.label.display_order)
                    .map((status, idx) => (
                      status.label.inuse === 1 && (
                        <button
                          type="button"
                          key={idx}
                          className={`status status--${this.beLowercase(status.label.colour)}`}
                          onClick={
                            () => {
                              this.props.handleChangeCustomStatusDropdown('status', status.id)
                              this.toggleStatus();
                            }
                          }
                        >{status.name}</button>
                      )
                    ))
                  }
                </div>
                <div
                  className="status-modal-overlay"
                  onClick={() => this.toggleStatus()}
                />
              </div>
            }
          </div>
        </div>
      </div>
    );
  };

  /*
  renderSkeleton = () => {
    return (
      <div className="container evac__controls">
        <div className="skeleton skeleton__field br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
        <div className="skeleton skeleton__field mt-10 br-10" />
      </div>
    );
  };
  */

  render() {
    /*
    if (!this.props.loading) {
      return (
        <div className="container evac__controls">
          <div className="evac__controls-row">
            {this.renderBuildingControl()}
            {this.renderFloorControl()}
          </div>
          {this.renderStatusControl()}
        </div>
      );
    } else {
      return <React.Fragment>{this.renderSkeleton()}</React.Fragment>;
    }
    */
    return (
      <div className="evac__controls">
        <div className="container">
          <div className="evac__controls-row">
            {this.renderBuildingControl()}
            {this.renderFloorControl()}
          </div>
          {this.renderStatusControl()}
          {this.renderStatusDropdown()}
        </div>
      </div>
    );
  }
}

export default Controls;
