import React from "react";

const ShowEvent = props => {
  let event = props.event.type;
  if (props.loading) {
    event = "Loading...";
  }
  if (props.no_event) {
    event = "Not Applicable";
  }
  return (
    <div className="row mt-20">
      <div className="col s3">
        <span className="floor__status--label">Event:</span>
      </div>
      <div className="col s9">
        {props.loading ? (
          <div className="skeleton skeleton__field br-10" />
        ) : (
          <div className="floor__status--event">{event}</div>
        )}
      </div>
    </div>
  );
};

export default ShowEvent;
