import React from 'react'
import { string, number, oneOfType } from 'prop-types'

export const LocationIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M64 72.7c13.9 0 25.2-11.3 25.2-25.2 0-13.9-11.3-25.2-25.2-25.2S38.8 33.6 38.8 47.5c0 13.9 11.3 25.2 25.2 25.2zm0-47.3c12.2 0 22.2 9.9 22.2 22.2 0 12.2-9.9 22.2-22.2 22.2s-22.2-9.9-22.2-22.2c0-12.3 9.9-22.2 22.2-22.2z" fill={fill || 'black'} /><path d="m64 115 29-50.2c6-10.5 6-23 0-33.5-6.1-10.5-16.9-16.8-29-16.8s-22.9 6.3-29 16.8c-6.1 10.5-6.1 23 0 33.5L64 115zM37.6 32.8C43.1 23.2 53 17.5 64 17.5s20.9 5.7 26.4 15.3c5.5 9.6 5.5 21 0 30.5L64 109 37.6 63.2c-5.5-9.5-5.5-20.9 0-30.4z" fill={fill || 'black'} /><path d="M64 45.6c4.8 0 8.6-3.9 8.6-8.6s-3.9-8.6-8.6-8.6-8.6 3.9-8.6 8.6 3.8 8.6 8.6 8.6zm0-14.2c3.1 0 5.6 2.5 5.6 5.6s-2.5 5.6-5.6 5.6-5.6-2.5-5.6-5.6 2.5-5.6 5.6-5.6z" fill={fill || 'black'} /><path d="M79.8 56c0-5.7-2.2-9.1-7.3-11.1l-1.2-.5-.7 1.1c-.9 1.5-4.4 2.8-6.6 2.8-2.2 0-5.7-1.3-6.6-2.8l-.6-1.1-1.2.5c-5.1 2-7.4 5.4-7.4 11.1v5.5h31.5V56zm-3 2.5H51.3V56c0-4.1 1.3-6.4 4.5-7.9 2.1 2.1 5.9 3.2 8.4 3.2 2.4 0 6.3-1.1 8.3-3.2 3.1 1.5 4.4 3.8 4.4 7.9v2.5z" fill={fill || 'black'} />
    </svg>
  )
}

LocationIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}


export const MapLocationIcon = ({ width, height, fill }) => {
  return (
    <svg
      width={width || '24'}
      height={height || '24'}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M23.3477 22.2L20.4077 15.03C20.3277 14.82 20.1777 14.64 19.9877 14.51C19.7977 14.39 19.5877 14.32 19.3577 14.32H17.1277C18.7977 11.93 19.6477 9.98 19.6477 8.53C19.6477 4.37 16.3077 1 12.2077 1C8.10766 1 4.76766 4.37 4.76766 8.53C4.76766 9.98 5.61766 11.93 7.28766 14.32H5.05766C4.82766 14.32 4.60766 14.39 4.42766 14.52C4.23766 14.64 4.08766 14.82 4.00766 15.03L1.05766 22.22C1.00766 22.37 0.987664 22.53 1.00766 22.68C1.02766 22.84 1.08766 22.98 1.16766 23.11C1.25766 23.24 1.37766 23.34 1.51766 23.41C1.65766 23.48 1.80766 23.52 1.95766 23.52H22.4577C22.6077 23.52 22.7577 23.48 22.8977 23.41C23.0377 23.34 23.1577 23.24 23.2477 23.11C23.3277 22.98 23.3877 22.84 23.4077 22.68C23.4277 22.53 23.4077 22.37 23.3477 22.2ZM6.29766 8.53C6.29766 5.22 8.94766 2.52 12.2077 2.52C15.4677 2.52 18.1177 5.22 18.1177 8.53C18.1177 9.86 17.0477 11.97 15.0177 14.62C14.5177 15.28 13.9677 15.95 13.3977 16.61C13.0277 17.04 12.6377 17.48 12.2077 17.94C11.7777 17.48 11.3877 17.04 11.0177 16.61C10.4477 15.94 9.89766 15.27 9.39766 14.62C7.36766 11.97 6.29766 9.86 6.29766 8.53ZM2.79766 22L5.31766 15.85H8.41766C8.87766 16.43 9.35766 17.02 9.86766 17.61C10.2677 18.07 10.6777 18.54 11.1477 19.03C11.2777 19.18 11.4377 19.3 11.6277 19.38C11.8077 19.46 12.0077 19.5 12.2077 19.5C12.4077 19.5 12.6077 19.46 12.7877 19.38C12.9777 19.3 13.1377 19.18 13.2777 19.03C13.7377 18.54 14.1577 18.07 14.5477 17.61C15.0577 17.02 15.5477 16.43 15.9977 15.85H19.0977L21.6177 22H2.79766Z" fill={fill || 'black'} />
      <path d="M15.5384 7.56008C15.4084 6.91008 15.0884 6.30008 14.6084 5.83008C14.1284 5.35008 13.5284 5.03008 12.8684 4.90008C12.6484 4.85008 12.4284 4.83008 12.2084 4.83008C11.7584 4.83008 11.3284 4.92008 10.9084 5.09008C10.2884 5.35008 9.75836 5.78008 9.38836 6.34008C9.00836 6.90008 8.81836 7.55008 8.81836 8.23008C8.81836 9.13008 9.16836 9.99008 9.80836 10.6301C10.4484 11.2701 11.2984 11.6201 12.2084 11.6201C12.8784 11.6201 13.5384 11.4201 14.0984 11.0501C14.6584 10.6801 15.0884 10.1501 15.3484 9.53008C15.5984 8.91008 15.6684 8.23008 15.5384 7.56008ZM14.0384 8.59008C13.9684 8.96008 13.7884 9.29008 13.5284 9.55008C13.2684 9.81008 12.9384 9.99008 12.5684 10.0601C12.4484 10.0801 12.3284 10.1001 12.2084 10.1001C11.9584 10.1001 11.7184 10.0501 11.4984 9.95008C11.1484 9.81008 10.8584 9.57008 10.6584 9.27008C10.4484 8.96008 10.3384 8.60008 10.3384 8.23008C10.3384 7.73008 10.5384 7.26008 10.8884 6.91008C11.2384 6.55008 11.7084 6.36008 12.2084 6.36008C12.5784 6.36008 12.9384 6.47008 13.2484 6.67008C13.5584 6.88008 13.7884 7.17008 13.9384 7.51008C14.0784 7.85008 14.1184 8.23008 14.0384 8.59008Z" fill={fill || 'black'} />
    </svg>
  )
}

MapLocationIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
}
