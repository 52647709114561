import React, { Component } from "react";

import BuildingSelect from "./BuildingSelect";
import BuildingInfo from "./BuildingInfo";
import FloorList from "./FloorList";

import "./scss/floor-status.scss";

import axios from "axios";

import {
  api_event_buildings_url,
  api_event_building_change_url
} from "../../../config";

class FloorStatus extends Component {
  state = {
    loading: false,
    no_event: true,
    buildings: [
      {
        id: 1,
        name: "Building A",
        selected: true
      },
      {
        id: 2,
        name: "Building B",
        selected: false
      },
      {
        id: 3,
        name: "Building C",
        selected: false
      }
    ],
    selected_building: {
      id: 1,
      name: "Building A",
      event: {
        id: 12,
        type: "Fire Evacuation",
        description: "Some fire evacuation description"
      },
      wardens: {
        total: 52,
        on_duty: 33,
        unknown: 12,
        not_available: 7,
        off_duty: 0
      },
      floors: [
        {
          id: 1,
          name: "Auditorium",
          wardens_count: 2,
          wardens: [
            {
              id: 1,
              name: "Testing test",
              status: "On_Duty"
            },
            {
              id: 2,
              name: "Peer peer",
              status: "Unknown"
            }
          ]
        },
        {
          id: 2,
          name: "Level 01",
          wardens_count: 4,
          wardens: [
            {
              id: 3,
              name: "Joe Rogan",
              status: "On_Duty"
            },
            {
              id: 4,
              name: "Nik Stha",
              status: "On_Duty"
            },
            {
              id: 5,
              name: "Testing name",
              status: "Unknown"
            },
            {
              id: 6,
              name: "name Test",
              status: "Not_Available"
            }
          ]
        },
        {
          id: 3,
          name: "Level 02",
          wardens_count: 4,
          wardens: [
            {
              id: 7,
              name: "James Cameroom",
              status: "On_Duty"
            },
            {
              id: 8,
              name: "Jack Black",
              status: "On_Duty"
            },
            {
              id: 9,
              name: "Nikolai Tesla",
              status: "On_Duty"
            },
            {
              id: 10,
              name: "Elon Musk",
              status: "Not_Available"
            }
          ]
        }
      ]
    }
  };

  loading = loadState => {
    this.setState({
      ...this.state,
      loading: loadState
    });
  };

  componentDidMount() {
    this.getEventBuildings();
  }

  getEventBuildings = () => {
    this.loading(true);

    axios
      .get(api_event_buildings_url)
      .then(resp => {
        if (resp.data.hasOwnProperty("status")) {
          console.log(resp.data.message);
        } else {
          this.setState({
            ...this.state,
            buildings: resp.data.buildings,
            selected_building: resp.data.selected_building,
            warden_id: resp.data.warden_id,
            no_event: false
          });
        }
      })
      .finally(resp => {
        this.loading(false);
      });
  };

  renderBuildings = () => {
    return this.state.buildings.map(bldg => {
      return (
        <option
          key={bldg.id + " " + bldg.name + " selectoption"}
          value={bldg.id}
        >
          {bldg.name}
        </option>
      );
    });
  };

  handleBuildingSelectChange = buildingId => {
    //make a ajax call to get the info about buildingId
    //and store it in the selected_building state
    this.changeSelectedBuilding(buildingId);
    let selectedBldgId = this.state.buildings
      .filter(bldg => {
        return bldg.id === buildingId;
      })
      .map(bldg => {
        return bldg.id;
      })[0];
    this.getSelectedBuildingData(selectedBldgId);
  };

  changeSelectedBuilding = buildingId => {
    var _buildings = [...this.state.buildings];
    _buildings.forEach(bldg => {
      if (bldg.id === buildingId) {
        bldg.selected = true;
      } else {
        bldg.selected = false;
      }
    });
    this.setState({ ...this.state, buildings: _buildings });
  };

  getSelectedBuildingData = buildingId => {
    //axios call to backend to get the building details
    if (!this.state.no_events) {
      this.loading(true);

      axios
        .post(
          api_event_building_change_url,
          { buildingId: buildingId }
        )
        .then(resp => {
          this.setState({
            ...this.state,
            selected_building: resp.data.selected_building
          });
        })
        .finally(resp => {
          this.loading(false);
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* <Loader loading={this.state.loading} /> */}
        <div className="container extended">
          <div className="col s12 floor__status">
            <BuildingSelect
              buildings={this.state.buildings}
              loading={this.state.loading}
              handleBuildingSelectChange={this.handleBuildingSelectChange}
              no_event={this.state.no_event}
            />
            <BuildingInfo
              building={this.state.selected_building}
              loading={this.state.loading}
              no_event={this.state.no_event}
            />
          </div>
        </div>
        <FloorList
          floors={this.state.selected_building.floors}
          loading={this.state.loading}
          no_event={this.state.no_event}
          warden_id = {this.state.warden_id}
        />
      </React.Fragment>
    );
  }
}

export default FloorStatus;
