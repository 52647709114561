import React from "react";

import message_unread_img from "./images/message_unread.png";
import "./scss/bubble.scss";

export const BUBBLE = {
  TO: "TO",
  FROM: "FROM"
};

const MessageBubble = props => {
  const renderMessageBubble = () => {
    let mainClass = "message__to";
    let offsetClass = "offset-s3";
    let messageRead = props.message.read;
    let type = props.type;
    if (type === BUBBLE.FROM) {
      mainClass = "message__from";
      offsetClass = "";
    }

    return (
      <div className={"row " + mainClass}>
        <div className={"col s9 " + offsetClass}>
          <div className={mainClass + "--info"}>
            {props.message.timestamp + " - "}
            <b className={mainClass + "--name"}>{props.message.name}</b>{" "}
            {" - " + props.message.role}
          </div>
          <div className={mainClass + "--bubble"}>{props.message.text}</div>
          {!messageRead && props.message.senderId === props.recipient.id ? (
            <div className={mainClass + "--unread"}>
              <img src={message_unread_img} alt="unread message" />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const renderSkeletonBubble = () => {
    let mainClass = "message__to";
    let offsetClass = "offset-s3";
    let type = props.type;
    if (type === BUBBLE.FROM) {
      mainClass = "message__from";
      offsetClass = "";
    }

    return (
      <div className={"row " + mainClass}>
        <div className={"col s9 " + offsetClass}>
          <div className={mainClass + "--info"} />
          <div className={mainClass + "--bubble skeleton"} />
        </div>
      </div>
    );
  };

  return !props.loading ? renderMessageBubble() : renderSkeletonBubble();
};

export default MessageBubble;
