import React from 'react'
import { string, number, oneOfType } from 'prop-types'

/*
  Chevron
  https://fontawesome.com/icons/chevron-right?f=classic&s=solid
*/
export const ArrowChevronIcon = ({ width, height, fill, direction }) => {
  if (direction === 'up') {
    return (
      <svg
        width={height || '16'}
        height={width || '16'}
        fill={fill || 'black'}
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={fill || 'black'}
          d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
        />
      </svg>
    )
  } else if (direction === 'left') {
    return (
      <svg
        width={height || '16'}
        height={width || '16'}
        fill={fill || 'black'}
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={fill || 'black'}
          d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"
        />
      </svg>
    )
  } else if (direction === 'right') {
    return (
      <svg
        width={height || '16'}
        height={width || '16'}
        fill={fill || 'black'}
        viewBox="0 0 448 512"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill={fill || 'black'}
          d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
        />
      </svg>
    )
  }
  return (
    <svg
      width={height || '16'}
      height={width || '16'}
      fill={fill || 'black'}
      viewBox="0 0 448 512"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill={fill || 'black'}
        d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
      />
    </svg>
  )
}

ArrowChevronIcon.propTypes = {
  width: oneOfType([number, string]),
  height: oneOfType([number, string]),
  fill: string,
  direction: string,
}
